import React, { useEffect, useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import axios from "axios";
import { url, setHeaders } from "../../../../slices/api";
import walet from '../../../../images/walet.png';
import meta2 from '../../../../images/meta2.png';
import Loader from './Loader';
import { Button, Modal } from 'react-bootstrap';
import { useAppSelector,useAppDispatch } from "../../../../reducer/store";
import { projectId } from "../../contract/Addresses";
import { USDT_ADDRESS } from '../../contract/Addresses';
import { USDC_ADDRESS } from '../../contract/Addresses';
import { http, createConfig, useAccount, useConnect } from 'wagmi';
import { mainnet, sepolia, arbitrumSepolia, arbitrum } from 'wagmi/chains';
import { coinbaseWallet, injected, walletConnect } from 'wagmi/connectors';
import { Buffer } from 'buffer/';
import Web3 from 'web3';
export const configure = createConfig({
	chains: [mainnet, sepolia, arbitrumSepolia, arbitrum],
	connectors: [
		injected(),
		coinbaseWallet(),
		walletConnect({ projectId }),
	],
	transports: {
		[mainnet.id]: http(),
		[sepolia.id]: http(),
		[arbitrumSepolia.id]: http(),
	},
})

const BuyOrderForm = ({ prop }) => {
	window.Buffer = window.Buffer || Buffer;
	const params = useParams();
	const [USDTprice, setUSDTprice] = useState(0);
	const [SEDTprice, setSEDTprice] = useState(0);
	const [USDCprice, setUSDCprice] = useState(0);
	const [tokenType, settokenType] = useState("");
	const [price, setprice] = useState(0);
	// const [deadline, setdeadline] = useState(0);
	const [quantity, setquantity] = useState(0);
	const [loading, setLoading] = useState(false);
	const [contactModal, setContactModal] = useState(false);
	const { address, addresses, isConnected } = useAccount();
	const { connectors, connect } = useConnect();
	const user = useAppSelector((state) => state.auth);


	const domainName = tokenType == 0 ? "USDT" :"USDC"
	const domainVersion = "1" 
	const chainId = 421614 
	const contractAddress = tokenType == 0 ? USDT_ADDRESS : USDC_ADDRESS

	
	const domain = {
		name: domainName,
		version: domainVersion,
		verifyingContract: contractAddress,
		chainId
	}

	const domainType = [
		{ name: 'name', type: 'string' },
		{ name: 'version', type: 'string' },
		{ name: 'chainId', type: 'uint256' },
		{ name: 'verifyingContract', type: 'address' },
	]

	const splitSig = (sig) => {
		// splits the signature to r, s, and v values.
		const pureSig = sig.replace("0x", "")

		const r = new Buffer(pureSig.substring(0, 64), 'hex')
		const s = new Buffer(pureSig.substring(64, 128), 'hex')
		const v = new Buffer((parseInt(pureSig.substring(128, 130), 16)).toString());
		return {
			r, s, v
		}
	}

	const signTyped = (dataToSign) => {
		const web3 = new Web3(Web3.givenProvider);
		// call this method to sign EIP 712 data
		return new Promise((resolve, reject) => {
			web3.currentProvider.sendAsync({
				method: "eth_signTypedData_v4",
				params: [address, dataToSign],
				from: address
			}, (err, result) => {
				if (err) return reject(err);
				resolve(result.result)
			})
		})
	}



	async function createPermit(spender, value, nonce, deadline) {
		const permit = {
			owner: address,
			spender,
			value,
			nonce,
			deadline
		}
		const Permit = [
			{ name: "owner", type: "address" },
			{ name: "spender", type: "address" },
			{ name: "value", type: "uint256" },
			{ name: "nonce", type: "uint256" },
			{ name: "deadline", type: "uint256" },
		]

		const dataToSign = JSON.stringify({
			types: {
				EIP712Domain: domainType,
				Permit: Permit
			},
			domain: domain,
			primaryType: "Permit",
			message: permit
		});

		const signature = await signTyped(dataToSign)
		const split = splitSig(signature)
		return {
			...split, signature
		}
	}

	useEffect(() => {
		if (isConnected && address && addresses?.length && user._id) {
			updateWalletsInDB(addresses, address, user._id);
		}
	}, [isConnected, address, addresses, user._id]);

	const updateWalletsInDB = async (walletAddresses, activeWallet, userID) => {
		try {
			// const response = 
			await axios.patch(
				`${url}/users/wallet/update/${userID}`,
				{
					walletAddresses,
					activeWallet,
				}
			);
			// const data = response.data;
			// if (response.ok) {
			// 	console.log("Wallets updated successfully:", data);
			// } else {
			// 	console.error("Error updating wallets:", data.message);
			// }
		} catch (error) {
			console.error("Failed to update wallets:", error);
		}
	};

	useEffect(() => {
		const USDTPrice = async () => {
			if (USDTprice === 0) {
				try {
					await axios.get(`${url}/products/usdt/${params.id}`)
						.then((result) => {
							setUSDTprice(result.data);
						})
				} catch (err) {
					console.log(err);
				}
			}
		};
		USDTPrice();
	})

	// async function sign() {
	// 	const permit = await createPermit(
	// 		prop.AdminWallet,
	// 		1000,
	// 		0,
	// 		1719067102
	// 	)
	// 	console.log(`r: 0x${permit.r.toString('hex')}, s: 0x${permit.s.toString('hex')}, v: ${permit.v}, sig: ${permit.signature}`)
	// }

	useEffect(() => {
		if (USDTprice === 0) {
			const USDTPrice = async () => {
				try {
					await axios.get(`${url}/products/usdt/${params.id}`)
						.then((result) => {
							setUSDTprice(result.data);
						})
				} catch (err) {
					console.log(err);
				}
			};
			USDTPrice();
		}
	},[USDTprice])

	useEffect(() => {
		if (USDCprice === 0) {
			const USDCPrice = async () => {

				try {
					await axios.get(`${url}/products/usdc/${params.id}`)
						.then((roundData) => {
							setUSDCprice(roundData.data);
						})
				} catch (err) {
					console.log(err);
				}
			}
			USDCPrice();
		}
	},[USDCprice])


	const CalculateValue = (_quantity) => {
		if (tokenType == 0) {
			let tokens = ((Number(price * 1e8) * (_quantity) * 1e6) / (USDTprice)).toFixed(0).toString();
			let fee = ((tokens / 1e10) * Number(prop.buySellingFee)).toFixed(0).toString();
			// setCalToken(Number(tokens) + Number(fee))
			const num = Number(tokens) + Number(fee)
			return num;
		} else if (tokenType == 1) {
			let tokens = ((Number(price * 1e8) * (_quantity) * 1e6) / (USDCprice)).toFixed(0).toString();
			let fee = ((tokens / (1e10)) * Number(prop.buySellingFee)).toFixed(0).toString();
			// setCalToken(Number(tokens) + Number(fee))
			const num = Number(tokens) + Number(fee)
			return num;
		} else {
			let tokens = ((Number(price * 1e8) * (_quantity) * 1e6) / Number(SEDTprice)).toFixed(0).toString();
			let fee = ((tokens / (1e10)) * Number(prop.buySellingFee));
			// setCalToken(Number(tokens) + Number(fee))
			const num = Number(tokens) + Number(fee)
			return num;
		}
	}
	const checkNumbers = useMemo(() => {
		return CalculateValue(quantity)
	}, [quantity])


	const PlaceOrder = async () => {
		try {
			setLoading(true);
			const deadline = new Date().getTime() + 604800* 1000
			// setdeadline(new Date().getTime() + 604800* 1000)
			const count = tokenType == 0 ? user.USDTnonces : user.USDCnonces
			await createPermit(
				prop.AdminWallet,
				checkNumbers,
				count,
				deadline
			)
				.then(async (aprv) => {
					// console.log(`r: 0x${aprv.r.toString('hex')}, s: 0x${aprv.s.toString('hex')}, v: ${aprv.v}, sig: ${aprv.signature}`)
					await axios.post(
						`${url}/buyerOrder`,
						{
							"PropertyAddress": prop.uid,
							"BuyersAddress": address,
							"Type_Of_Currency": tokenType,
							"Number_of_Tokens": quantity,
							"Price_of_Tokens": price,
							"Usdt_Usdc": checkNumbers,
							"r": `0x${aprv.r.toString('hex')}`,
							"s": `0x${aprv.s.toString('hex')}`,
							"v": `${aprv.v}`,
							"signature": aprv.signature,
							"expireIn": deadline,
							"Statue": false,
						},
						setHeaders()
					)
					setLoading(false);
				})
		} catch (error) {
			setLoading(false);
			console.log("First Approve Error", error);
		}
	};

	const connectorImages = {
		MetaMask: meta2,
		WalletConnect: walet,
	};

	return (
		<>
			{isConnected == true ? (
				<>
					{loading ? (
						<div className="text-center">
							<Button
								className="btn w-100" style={{ backgroundColor: "white" }}
							>
								<Loader />
							</Button>
						</div>
					) : (
						<form>
							<div className="sell-blance">
								<label className="form-label text-primary">Pay with</label>
								<div className="form-label blance"><span>Type of Currnecy </span>
								</div>
								<div className="input-group">
									<select
										className="form-control"
										onChange={(e) => settokenType(e.target.value)}
										required
									>
										<option value="">Select</option>
										<option value="0">USDT</option>
										<option value="1">USDC</option>
									</select>
									<span className="input-group-text">Payable Token Types</span>
								</div>
							</div>
							<div className="sell-blance">
								<label className="form-label text-primary">
									Limit Price Per Token
								</label>
								<div className="input-group">
									<input
										className="form-control"
										onChange={(e) => setprice(e.target.value)}
										placeholder="0.00"
									/>
									<span className="input-group-text">USD</span>
								</div>
							</div>
							<div className="sell-blance">
								<label className="form-label text-primary">Property Token Quantity</label>
								<div className="input-group">
									<input
										type="text"
										className="form-control"
										onChange={(e) => setquantity(e.target.value)}
										placeholder="0.00"
									/>
									{tokenType == 0 ? (
										<span className="input-group-text">
											<span className="text-warning">
												{Number(checkNumbers / 1e6).toFixed(3)}</span> {""}-USDT</span>
									) : (
										<span className="input-group-text">
											<span className="text-warning">
												{Number(checkNumbers / 1e6).toFixed(3)}</span> {""}-USDC</span>
									)}
								</div>
							</div>
							<div className="text-center">
								<Button
									className="btn btn-primary w-75"
									onClick={() => PlaceOrder()}
								>
									Place Order
								</Button>
								
							</div>
							{/* {prop.isStartSelling == true || prop.isReStartSelling == true ? (
								<>
									{tokenType == 0 ? (
										<>
										</>
									) :
										<div className="text-center">
											<Button
												className="btn btn-primary w-75"
												onClick={() => BuybyUsdc()}
											>
												Buy Tokens

											</Button>
										</div>
									}
								</>
							) : (
								<>
									<div className="text-center">
										<Link className="btn btn-primary w-75">Selling is Closed</Link>
									</div>
								</>
							)} */}
						</form>
					)}

				</>
			) : (
				<>
					<div className="row">
						<div className="display-1  text-center fw-bold">
							<h5>
								To access the Dashboard
								Please connect a wallet
							</h5>
							<div>
								<button className="btn btn-primary" onClick={() => setContactModal(true)}>Connect Wallet

								</button>
							</div>
						</div>
					</div>
					<Modal className="modal fade" id="exampleModal" centered show={contactModal} onHide={setContactModal}>
						<div className="modal-content">
							<div className="modal-header">
								<h2 className="modal-title" id="exampleModalLabel">Connect a Wallet</h2>
								<button type="button" className="btn-close" onClick={() => setContactModal(false)}></button>
							</div>
							<div className=" text-center mt-5 fw-bold">
								<div className="flex flex-row cursor-pointer flex-wrap sm:mt-10 ">
									<div className="row">
										{connectors.map((connector, index) => (
											<div className="tagcloud mt-1 col" key={connector.id}>
												<Button
													type="button"
													style={{
														backgroundColor: "white",
														borderRadius: "16px",
														color: "#3c0987",
													}}
													onClick={() => connect({ connector })}
													className=" font-medium rounded-lg text-sm px-3 py-2.5 text-center inline-flex items-center ">
													<img
														loading="lazy"
														width="35"
														height="30"
														// src={wal}
														src={connectorImages[connector.name]}
														alt="meta"
														marginLeft="30"
														marginRight="20"
													/>
													<h5 className="mr-3 ml-2 mt-2"
														style={{ marginRight: "3px", marginLeft: "13px" }}
														key={connector.uid}
													>

														{connector.name}
													</h5>
												</Button>
											</div>
										))}
									</div>
								</div>
							</div>
							<div className="modal-body">
								<label className="form-label d-block fw-bold">Select your favourite wallet to Access Dashbaord.</label>
							</div>
							<div className="modal-footer">
								<a>By connecting a wallet, you agree to Uniswap Labs' Terms of Service and consent to its Privacy Policy. </a>
								<button type="button" className="btn btn-secondary" onClick={() => setContactModal(false)}>Close</button>
							</div>
						</div>
					</Modal>
				</>
			)}

		</>
	)
}
export default BuyOrderForm;