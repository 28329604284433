import React, { useMemo, useEffect, useState } from 'react';
import { useTable, useGlobalFilter, useFilters, usePagination } from 'react-table';
import MOCK_DATA from './MOCK_DATA_2.json';
import { COLUMNS } from './Columns';
import './filtering.css';
import { useAppSelector } from '../../../reducer/store';
import { useAccount } from "wagmi";
import { toast } from "react-toastify";
import bigInt from "big-integer";

export const FilteringTable = () => {
	const [propdata, setpropdata] = useState([]);
	const [sum, setsum] = useState(0);
	const { items: dat } = useAppSelector((state) => state.products);
	const { Indata: datas } = useAppSelector((state) => state.rent);
	const user = useAppSelector((state) => state.auth);
	const { address } = useAccount();
	const columns = useMemo(() => COLUMNS, [])
	const data = useMemo(() => propdata, [])



	useEffect(() => {
		if (user._id) {
			const fetchUserData = async () => {
				try {
					let checkdata = [];
					dat.forEach((key) => {
						const itemsData = key.tokenHolder.filter(
							(wallet) => wallet.buyer === address
						)
						console.log("itemsData", itemsData);
						const summedValues = itemsData.reduce((acc, item) => {
							acc[item.buyer] = (acc[item.buyer] || bigInt(0)) + bigInt(item.TokensOrder);
							return acc;
						}, {});
						checkdata.push({ id: key._id, buyers: summedValues });
					})
					setpropdata(checkdata)

				} catch (Error) {
					toast.error("Error fetching user data", Error);
				}
			};
			fetchUserData();
		}
	}, [user._id]);

	console.log("check", propdata);
	const tableInstance = useTable({
		columns,
		data,
		initialState: { pageIndex: 0 }
	}, useFilters, useGlobalFilter, usePagination)


	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		state,
		page,
		gotoPage,
		pageCount,
		pageOptions,
		nextPage,
		previousPage,
		canNextPage,
		canPreviousPage,
		setGlobalFilter,
	} = tableInstance


	const { globalFilter, pageIndex } = state


	return (
		<>
			<div className="table-responsive">
				<table {...getTableProps()} className="table dataTable display">
					{/* <thead>
						{headerGroups.map(headerGroup => (
							<tr {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map(column => (
									<th {...column.getHeaderProps()}>
										{column.render('Header')}
									</th>
								))}
							</tr>
						))}
					</thead> */}
					{/* <tbody {...getTableBodyProps()} className="" >

						{page.map((row) => {
							prepareRow(row)
							return (
								<tr {...row.getRowProps()}>
									{row.cells.map((cell) => {
										return <td {...cell.getCellProps()}>
											{cell.render('Cell')}
										</td>
									})}
								</tr>
							)
						})}
					</tbody> */}
					<thead>
						<tr>
							<th>Property ID</th>
							<th>Property Tokens</th>
						</tr>
					</thead>
					{propdata && propdata?.map((item) => (
						<tbody key={item.id}>
							{item.buyers[address] > 0 ?
								<tr className="even" role="row">
									<td>{item.id}</td>
									<td>{item.buyers[address]/1e18}</td>
								</tr>
								:""}
						</tbody>
					))}
				</table>
				<div className="d-flex justify-content-between">
					<span>
						Page{' '}
						<strong>
							{pageIndex + 1} of {pageOptions.length}
						</strong>{''}
					</span>
					<span className="table-index">
						Go to page : {' '}
						<input type="number"
							className="ml-2"
							defaultValue={pageIndex + 1}
							onChange={e => {
								const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0
								gotoPage(pageNumber)
							}}
						/>
					</span>
				</div>
				<div className="text-center mb-3">
					<div className="filter-pagination  mt-3">
						<button className=" previous-button" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>{'<<'}</button>

						<button className="previous-button" onClick={() => previousPage()} disabled={!canPreviousPage}>
							Previous
						</button>
						<button className="next-button" onClick={() => nextPage()} disabled={!canNextPage}>
							Next
						</button>
						<button className=" next-button" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>{'>>'}</button>
					</div>
				</div>
			</div>
		</>
	)

}
export default FilteringTable;