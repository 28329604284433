import React, { Fragment, useReducer } from "react";
import PageTitle from "../layouts/PageTitle";
import { Modal } from "react-bootstrap";
import { useAppSelector, useAppDispatch } from '../../reducer/store';
import { propertyLLCDelete } from "../../slices/llcSlice";
import avatar1 from "../../images/product/1.jpg";



const init = false;
const reducer = (state, action) => {
  if (action.type === 'reviewModal') {
    return { ...state, reviewModal: !state.reviewModal }
  }
  return state;
}



const BlogList = () => {
  const dispatchs = useAppDispatch();
  const [state, dispatch] = useReducer(reducer, init);
  const { blogs: data, status } = useAppSelector((state) => state.blogdb);
  const PF = "http://localhost:5000/imges/";

  const handleDelete = (id) => {
    dispatchs(propertyLLCDelete(id));
  };

  return (
    <Fragment>
      <PageTitle
        activeMenu="Blogs"
        motherMenu="Properties"
        pageContent="Validation"
      />
      <div className="row">
      {data &&
        data?.map((blog) => (
          <div key={blog._id}>
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="row m-b-30">
                        <div className="col-md-5">
                          <div className="new-arrival-product mb-4 mb-xxl-4 mb-md-0">
                            <div className="new-arrivals-img-contnent">
                              <img className="img-fluid" src={PF + blog.photo} alt={blog.name} />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-7">
                          <div className="new-arrival-content position-relative">
                              {/* <Link to="ecom-product-detail"> */}
                                <h3>{blog.title}</h3>
                            <p>
                            Created By: <span className="item">{blog.name}</span>{" "}
                            </p>
                            <p>
                              Date:{" "}
                              <span className="item">
                                {" "}
                                {new Date(blog.createdAt).toDateString()}{" "}
                                <i className="fa fa-check-circle text-success" />
                              </span>
                            </p>
                            <p className="text-content">
                              {blog.desc}
                            </p>
                            <button className="btn btn-success fs-8"
                             onClick={() => handleDelete(blog._id)}>
                            Delete
                          </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            {/* review */}
            <Modal show={state.reviewModal} onHide={() => dispatch({ type: 'reviewModal' })} className="modal fade" id="reviewModal">
              <>
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">Review</h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-dismiss="modal"
                      onClick={() => dispatch({ type: 'reviewModal' })}
                    >
                    </button>
                  </div>
                  <div className="modal-body">
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();

                        dispatch({ type: 'reviewModal' })
                      }}
                    >
                      <div className="text-center mb-4">
                        <img
                          className="img-fluid rounded"
                          width={78}
                          src={avatar1}
                          alt="DexignZone"
                        />
                      </div>
                      <div className="form-group">
                        <div className="rating-widget mb-4 text-center">
                          {/* Rating Stars Box */}
                          <div className="rating-stars">
                            <ul
                              id="stars"
                              className="d-flex justify-content-center align-items-center"
                            >
                              <li>
                                <i className="fa fa-star me-1" />
                              </li>{" "}
                              <li>
                                <i className="fa fa-star me-1" />
                              </li>{" "}
                              <li>
                                <i className="fa fa-star me-1" />
                              </li>{" "}
                              <li>
                                <i className="fa fa-star me-1" />
                              </li>{" "}
                              <li>
                                <i className="fa fa-star" />
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="form-group mb-3">
                        <textarea
                          className="form-control"
                          placeholder="Comment"
                          rows={5}
                          defaultValue={""}
                        />
                      </div>
                      <button className="btn btn-success btn-block">RATE</button>
                    </form>
                  </div>
                </div>
              </>
            </Modal>
          </div>
        ))}
    </div>
    </Fragment>
  );
};

export default BlogList;
