import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { connect, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Dropdown } from "react-bootstrap";
import { url } from "../../../slices/api";
import axios from "axios";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import LogoutPage from './logoutPage';
import { Modal } from 'react-bootstrap';
import United from "../../../images/United.png";
import avatar from "../../../images/avatar/jush.png";
import profile from "../../../images/profile/about1.png";
import meta2 from "../../../images/meta2.png";
import walet from '../../../images/walet.png';
import wal from "../../../images/wal.svg";
import logout from "../../../images/logout.png";
import prof from "../../../images/profile/prof.jpg";
import logout1 from "../../../images/logout1.png";
import { logoutUser } from "../../../slices/authSlice";
import { useAppDispatch, useAppSelector } from '../../../reducer/store';
import { useAccount, useConnect, useDisconnect } from "wagmi";
import { IoIosHome } from "react-icons/io";
import { FcViewDetails } from "react-icons/fc";
import { AiOutlineDashboard } from "react-icons/ai";
import { LuLayoutDashboard } from "react-icons/lu";


const Header = () => {
	const [contactModal, setContactModal] = useState(false);
	const [headerFix, setheaderFix] = useState(false);
	const user = useSelector((state) => state.auth);
	const { address, isConnected } = useAccount();
	const { disconnect } = useDisconnect();
	const { connectors, connect } = useConnect()

	const connectorImages = {
		MetaMask: meta2,
		WalletConnect: walet,
	};

	const handleDisconnect = async () => {
		if (isConnected && address) {
			console.log(`Disconnecting wallet with address: ${address}`);
			try {
				const response = await axios.patch(
					`${url}/users/wallet/disconnect/${user._id}`,
					{
						walletAddress: address, // Send the address to mark inactive
					}
				);
				console.log(response.data.message);
			} catch (error) {
				console.error("Error disconnecting wallet:", error);
			}
		}
		disconnect(); // Call disconnect function from WAGMI
	};

	useEffect(() => {
		window.addEventListener("scroll", () => {
			setheaderFix(window.scrollY > 50);
		});
	}, []);


	return (
		<div className={`header ${headerFix ? "is-fixed" : ""}`}>
			<div className="header-content">
				<nav className="navbar navbar-expand">
					<div className="collapse navbar-collapse justify-content-between">
						<div className="header-left">
							<div
								className="dashboard_bar"
								style={{ textTransform: "capitalize" }}
							>
							</div>
						</div>
						<div className="navbar-nav header-right">
							<div className="nav-item d-flex align-items-center">
								{user._id ? (
									<>
									<div className="input-group">
											<Link to={"/"} className="btn mx-auto btn-primary"
												style={{ color: "white", fontSize: "13px" }}>Marketplace</Link>
										</div>

										<div className="input-group" style={{ marginLeft: "10px" }}>
											<Link to={"/dashboard"} className="btn mx-auto btn-primary"
												style={{ color: "white", fontSize: "13px" }}>Account</Link>
										</div>
									</>
								) :
									<>
									<div className="input-group">
											<Link to={"/"} className="btn mx-auto btn-primary"
												style={{ color: "white", fontSize: "13px" }}>Marketplace</Link>
										</div>
										<div className="input-group" style={{ marginLeft: "10px" }}>
											<Link to={"/login"} className="btn mx-auto btn-primary"
												style={{ color: "white", fontSize: "13px" }}>Login</Link>
										</div>
										<div className="input-group" style={{ marginLeft: "10px" }}>
											<Link to={"/page-register"} className="btn mx-auto btn-primary"
												style={{ color: "white", fontSize: "13px" }}>Signup</Link>
										</div>
									</>
								}
							</div>
							<div className="dz-side-menu">
								<div className="sidebar-social-link ">
								</div>
								<ul>
									<Dropdown as="li" className="nav-item dropdown header-profile">
										<Dropdown.Toggle variant="" as="a" className="nav-link i-false c-pointer">
											<img src={prof} width={20} alt="" />
										</Dropdown.Toggle>
										<Dropdown.Menu align="right" className="dropdown-menu dropdown-menu-end">
											{isConnected == true ? (
												<>
													<div className="row">
														<div className="display-1  text-center fw-bold">
															<h5>
																{address?.substring(0, 10) + "...."}
															</h5>
															<div>
															</div>
														</div>
													</div>
													<button onClick={() => handleDisconnect()} as="button"
														type="button"
														style={{
															backgroundColor: "#9568FF",
															color: "white",
															borderRadius: "8px",
															borderColor: "#9568FF",
															marginLeft: "10px",
															marginTop: "10px",
															// height:"45px",
															width: "150px",
														}}
													>
														Disconnect
														<img
															src={wal}
															alt="wallets"
															height="5px"
															width="5px"
															style={{ marginLeft: "10px" }}
														/>
													</button>
												</>
											) : (
												<>
													<div className="row">
														<div className="display-1 text-center fw-bold">
															<h5>
																Please to
																the connect a wallet
															</h5>
															<div>
																<button className="btn btn-primary" onClick={() => setContactModal(true)}>Connect Wallet
																</button>
															</div>
														</div>
													</div>
												</>
											)}
											{user._id ? (
												<LogoutPage />
											) : ""}
										</Dropdown.Menu>
									</Dropdown>
								</ul>

								<Modal className="modal fade" id="exampleModal" centered show={contactModal} onHide={setContactModal}>
									<div className="modal-content">
										<div className="modal-header">
											<h2 className="modal-title" id="exampleModalLabel">Connect a Wallet</h2>
											<button type="button" className="btn-close" onClick={() => setContactModal(false)}></button>
										</div>
										<div className=" text-center mt-5 fw-bold">
											<div className="flex flex-row cursor-pointer flex-wrap sm:mt-10 ">

												<div className="row">
													{connectors.map((connector, index) => (
														<div className="tagcloud mt-1 col" key={connector.id}>
															<Button
																type="button"
																style={{
																	backgroundColor: "white",
																	borderRadius: "16px",
																	color: "#3c0987",
																}}
																className=" font-medium rounded-lg text-sm px-3 py-2.5 text-center inline-flex items-center ">
																<img
																	loading="lazy"
																	width="35"
																	height="30"
																	src={connectorImages[connector.name]}
																	alt="meta"
																	marginLeft="30"
																	marginRight="20"
																/>
																<h5 className="mr-3 ml-2 mt-2"
																	style={{ marginRight: "3px", marginLeft: "13px" }}
																	key={connector.uid}
																	onClick={() => connect({ connector })}>

																	{connector.name}
																</h5>
															</Button>
														</div>
													))}
												</div>
											</div>
										</div>
										<div className="modal-body">
											<label className="form-label d-block fw-bold">Select your favourite wallet to Access Dashbaord.</label>
										</div>
										<div className="modal-footer">
											<span>By connecting a wallet, you agree to Uniswap Labs' Terms of Service and consent to its Privacy Policy. </span>
											<button type="button" className="btn btn-secondary" onClick={() => setContactModal(false)}>Close</button>
										</div>
									</div>
								</Modal>
							</div>

						</div>


					</div>
				</nav>
			</div>
		</div>
	);
};

export default Header;
