import React, { useState, useEffect, Fragment } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from "react-redux";
import styled from "styled-components";
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import { productsCreate } from "../../slices/productsSlice";
import { useAppSelector, useAppDispatch } from '../../reducer/store';
import { Button } from "react-bootstrap";
import { Modal } from 'react-bootstrap';
import walet from '../../images/walet.png';
import meta2 from '../../images/meta2.png';
import { useAccount, useConnect, useDisconnect } from "wagmi";

const PropertyCreate = () => {
	const dispatch = useAppDispatch()
	const { createStatus } = useSelector((state) => state.products);
	const [productImg, setProductImg] = useState("");
	const [uid, setuid] = useState("");
	const [name, setName] = useState("");
	const [location, setlocation] = useState("");
	const [propertytype, setpropertytype] = useState("");
	const [bedroom, setbedroom] = useState("");
	const [bathroom, setbathroom] = useState("");
	const [area, setarea] = useState("");
	const [propaddress, setpropaddress] = useState("");
	const [desc, setDesc] = useState("");
	const [idtake, setidtake] = useState("null");
	const [contactModal, setContactModal] = useState(false);
	// const [isConnected, setIsConnected] = useState(false);
	const { web3, contract, accounts, status } = useAppSelector((state) => state.web3Connect);
	const auth = useSelector((state) => state.auth);
	const { address, isConnected } = useAccount();
	const { connectors, connect } = useConnect()
	const { disconnect } = useDisconnect();

	const handleProductImageUpload = (e) => {
		const file = e.target.files[0];

		TransformFileData(file);
	};

	const TransformFileData = (file) => {
		const reader = new FileReader();

		if (file) {
			reader.readAsDataURL(file);
			reader.onloadend = () => {
				setProductImg(reader.result);
			};
		} else {
			setProductImg("");
		}
	};

	useEffect(() => {
		setidtake(auth._id);
	});
	// const handleblockchain = () => {
	// 	dispatch(loadBlockchain());
	// 	setIsConnected(true);
	//   };

	const handleSubmit = async (e) => {
		e.preventDefault();

		dispatch(
			productsCreate({
				id: idtake, values: {
					uid,
					name,
					location,
					propertytype,
					bedroom,
					bathroom,
					area,
					propaddress,
					desc,
					image: productImg,
				}
			})
		);
	};

	
	const connectorImages = {
		MetaMask: meta2,
		WalletConnect: walet,
	};

	return (
		<Fragment>
			{isConnected == true ?
				(
					<section>
						<form onSubmit={handleSubmit}>
							<div className="row">
								<div className="col-xl-9 col-lg-8">
									<div className="card">
										<div className="card-header">
											<h4 className="card-title">Create Property</h4>
										</div>
										<div className="card-body">
											<div className="row">
												<div className="col-lg-6 mb-2">
													<div className="form-group mb-3">
														<label className="text-label">Property Picture*</label>
														<input id="imgUpload" accept="image/*" type="file"
															onChange={handleProductImageUpload}
															required />
													</div>
												</div>
												<div className="col-lg-6 mb-2">
													<div className="form-group mb-3">
														<label className="text-label">States*</label>
														<select className="form-control" id="val-skill" name="val-skill"
															onChange={(e) => setlocation(e.target.value)}
															required>
															<option value="">All States</option>
															<option value="Alabama">Alabama</option>
															<option value="Alaska">Alaska</option>
															<option value="Arizona">Arizona</option>
															<option value="Arkansas">Arkansas</option>
															<option value="California">California</option>
															<option value="Colorado">Colorado</option>
															<option value="Connecticut">Connecticut</option>
															<option value="Delaware">Delaware</option>
															<option value="Florida">Florida</option>
															<option value="Georgia">Georgia</option>
															<option value="Hawaii">Hawaii</option>
															<option value="Idaho">Idaho</option>
															<option value="Illinois">Illinois</option>
															<option value="Indiana">Indiana</option>
															<option value="Iowa">Iowa</option>
															<option value="Kansas">Kansas</option>
															<option value="Kentucky">Kentucky</option>
															<option value="Louisiana">Louisiana</option>
															<option value="Maine">Maine</option>
															<option value="Maryland">Maryland</option>
															<option value="Massachusetts">Massachusetts</option>
															<option value="Michigan">Michigan</option>
															<option value="Minnesota">Minnesota</option>
															<option value="Mississippi">Mississippi</option>
															<option value="Missouri">Missouri</option>
															<option value="Montana">Montana</option>
															<option value="Nebraska">Nebraska</option>
															<option value="Nevada">Nevada</option>
															<option value="New Hampshire">New Hampshire</option>
															<option value="New Jersey">New Jersey</option>
															<option value="New Mexico">New Mexico</option>
															<option value="New York">New York</option>
															<option value="North Carolina">North Carolina</option>
															<option value="North Dakota">North Dakota</option>
															<option value="Ohio">Ohio</option>
															<option value="Oklahoma">Oklahoma</option>
															<option value="Oregon">Oregon</option>
															<option value="Pennsylvania">Pennsylvania</option>
															<option value="Rhode Island">Rhode Island</option>
															<option value="South Carolina">South Carolina</option>
															<option value="South Dakota">South Dakota</option>
															<option value="Tennessee">Tennessee</option>
															<option value="Texas">Texas</option>
															<option value="Utah">Utah</option>
															<option value="Vermont">Vermont</option>
															<option value="Virginia">Virginia</option>
															<option value="Washington">Washington</option>
															<option value="West Virginia">West Virginia</option>
															<option value="Wisconsin">Wisconsin</option>
															<option value="Wyoming">Wyoming</option>
															<option value="District of Columbia">District of Columbia</option>
															<option value="American Samoa">American Samoa</option>
															<option value="Guam">Guam</option>
															<option value="Northern Mariana Islands">Northern Mariana Islands</option>
															<option value="Northern Mariana Islands">Northern Mariana Islands</option>
															<option value="Puerto Rico">Puerto Rico</option>
															<option value="California"> U.S. Virgin Islands</option>
														</select>
													</div>
												</div>
												<div className="col-lg-6 mb-2">
													<div className="form-group mb-3">
														<label className="text-label">Building Types*</label>
														<select className="form-control" id="val-skill" name="val-skill"
															onChange={(e) => setpropertytype(e.target.value)}
															required>
															<option value="">Building Types</option>
															<option value="Single Family">Single Family</option>
															<option value="Multiple Family">Multiple Family</option>
															<option value="Mised Use">Mised Use</option>
														</select>
													</div>
												</div>
												<div className="col-lg-6 mb-2">
													<div className="form-group mb-3">
														<label className="text-label">Property Name*</label>
														<input type="test" className="form-control" placeholder="Property Name"
															onChange={(e) => setName(e.target.value)}
															required />
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="col-xl-3 col-lg-4">
									<div className="card">
										<div className="card-header">
											<h4 className="card-title">Property Picture</h4>
										</div>
										<ImagePreview>
											{productImg ? (
												<>
													<img src={productImg} alt="error!" />
												</>
											) : (
												<h4>Property image upload preview will appear here!</h4>
											)}
										</ImagePreview>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-sm-4 mb-2">
									<input className="form-control" type="number" placeholder="Area in Sq/ft"
										onChange={(e) => setarea(e.target.value)} required />
								</div>
								<div className="col-6 col-sm-4 mb-2">
									<div className="form-group">
										<input className="form-control" placeholder="Bedroom" type="number"
											onChange={(e) => setbedroom(e.target.value)} required />
									</div>
								</div>
								<div className="col-6 col-sm-4 mb-2">
									<div className="form-group">
										<input className="form-control" type="number" placeholder="Bathroom"
											onChange={(e) => setbathroom(e.target.value)} required />
									</div>
								</div>
							</div>
							<div className="row">
							<div className="col-12 col-sm-4 mb-2" style={{ display: "flex", flexWrap: "wrap" }}>
									<textarea style={{
										border: "0", borderRadius: "0.625rem",
										backgroundColor: "#fff",
										color: "#6e6e6e",
										lineHeight: "2.4",
										fontSize: "1rem",
										borderColor: " #eeee",
										padding: "0.375rem 0.75rem",
										display: "block",
										margin: "0",
										fontFamily: "inherit",
										// height:"3rem",
										// padding: 0.375rem 0.75rem;
										// height: 3rem;
									}}
										id="val-suggestions"
										name="val-suggestions"
										rows="3"
										cols="50"
										placeholder="Complete Property Address"
										onChange={(e) => setpropaddress(e.target.value)} required
									></textarea>
								</div>
								<div className="col-12 col-sm-4 mb-2" 
								style={{ display: "flex", flexWrap: "wrap", 
								paddingRight: "5.75rem" }}>
									<div className="form-group">
										<textarea style={{
											border: "0", borderRadius: "0.625rem",
											backgroundColor: "#fff",
											color: "#6e6e6e",
											lineHeight: "2.4",
											fontSize: "1rem",
											borderColor: " #eeee",
											padding: "0.375rem 0.75rem",
											display: "block",
											margin: "0",
											fontFamily: "inherit",
											// padding: 0.375rem 0.75rem;
											// height: 3rem;
										}}
											// className="form-control"
											id="val-suggestions"
											name="val-suggestions"
											rows="3"
											cols="50"
											placeholder="Complete Property Description"
											onChange={(e) => setDesc(e.target.value)} required
										></textarea>
									</div>
								</div>
								<div className="text-end toolbar toolbar-bottom p-2">
									<button type="submit" className="btn btn-primary sw-btn-next" >
										{createStatus === "pending" ? "Submitting"
											:
											<>
												{createStatus === "success" ? "Submitted" : "Submit"}
											</>
										}

									</button>
								</div>
							</div>
						</form>
					</section>
				) : (
					<>
						<div className="row">
							<div className="display-1  text-center fw-bold">
								<h5>
									To access the Dashboard
									Please connect a wallet
								</h5>
								<div>
									<button className="btn btn-primary" onClick={() => setContactModal(true)}>Connect Wallet
										{/* <svg className="ms-2" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path d="M12 3C7.05 3 3 7.05 3 12C3 16.95 7.05 21 12 21C16.95 21 21 16.95 21 12C21 7.05 16.95 3 12 3ZM12 19.125C8.1 19.125 4.875 15.9 4.875 12C4.875 8.1 8.1 4.875 12 4.875C15.9 4.875 19.125 8.1 19.125 12C19.125 15.9 15.9 19.125 12 19.125Z" fill="white" />
												<path d="M16.3503 11.0253H12.9753V7.65029C12.9753 7.12529 12.5253 6.67529 12.0003 6.67529C11.4753 6.67529 11.0253 7.12529 11.0253 7.65029V11.0253H7.65029C7.12529 11.0253 6.67529 11.4753 6.67529 12.0003C6.67529 12.5253 7.12529 12.9753 7.65029 12.9753H11.0253V16.3503C11.0253 16.8753 11.4753 17.3253 12.0003 17.3253C12.5253 17.3253 12.9753 16.8753 12.9753 16.3503V12.9753H16.3503C16.8753 12.9753 17.3253 12.5253 17.3253 12.0003C17.3253 11.4753 16.8753 11.0253 16.3503 11.0253Z" fill="white" />
											</svg> */}
									</button>
									{/* </div>
								</div> */}
								</div>
							</div>
						</div>
						<Modal className="modal fade" id="exampleModal" centered show={contactModal} onHide={setContactModal}>
							<div className="modal-content">
								<div className="modal-header">
									<h2 className="modal-title" id="exampleModalLabel">Connect a Wallet</h2>
									<button type="button" className="btn-close" onClick={() => setContactModal(false)}></button>
								</div>
								<div className=" text-center mt-5 fw-bold">
									<div className="flex flex-row cursor-pointer flex-wrap sm:mt-10 ">
									<div className="row">
										{connectors.map((connector, index) => (
											<div className="tagcloud mt-1 col" key={connector.id}>
												<Button
													type="button"
													style={{
														backgroundColor: "white",
														borderRadius: "16px",
														color: "#3c0987",
													}}
													className=" font-medium rounded-lg text-sm px-3 py-2.5 text-center inline-flex items-center ">
													<img
														loading="lazy"
														width="35"
														height="30"
														// src={wal}
														src={connectorImages[connector.name]}
														alt="meta"
														marginLeft="30"
														marginRight="20"
													/>
													<h5 className="mr-3 ml-2 mt-2"
														style={{ marginRight: "3px", marginLeft: "13px" }}
														key={connector.uid}
														onClick={() => connect({ connector })}>

														{connector.name}
													</h5>
												</Button>
											</div>
										))}
									</div>
									</div>
								</div>
								<div className="modal-body">
									<label className="form-label d-block fw-bold">Select your favourite wallet to Access Dashbaord.</label>
								</div>
								<div className="modal-footer">
									<a>By connecting a wallet, you agree to SecondaryDAO' Terms of Service and consent to its Privacy Policy. </a>
									<button type="button" className="btn btn-secondary" onClick={() => setContactModal(false)}>Close</button>
								</div>
							</div>
						</Modal>
					</>
				)}
		</Fragment >
	);
};

export default PropertyCreate;



const ImagePreview = styled.div`
  margin: 2rem 2rem 2rem 2rem;
  padding: 2rem;
  height:150px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.1rem;
  color: rgb(78, 78, 78);

  img {
    max-width: 100%;
    max-height: 100%;
  }
`;
