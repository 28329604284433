import React, { Fragment, useEffect, useState } from "react";
import { Link, useParams } from 'react-router-dom';
import { Nav, Tab, Modal } from "react-bootstrap";
import axios from "axios";
import { url } from "../../slices/api";
import { useAppSelector, useAppDispatch } from '../../reducer/store';
import { IoCheckmarkDone } from "react-icons/io5";
import { RiFileCopyLine } from 'react-icons/ri';
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import arbi from "../../images/svg/arbi.svg";
import wal from "../../images/wal.svg";
import Button from 'react-bootstrap/Button';
import { useAccount, useDisconnect } from "wagmi";
import Footr from "../layouts/Footr";
import MarketNav from "../layouts/MarketNav";
import OrderFormss from './Dashboard/Dashboard/OrderFormss';
import BuyOrderForm from "./Dashboard/Dashboard/BuyOrderForm";
import SellOrderForm from "./Dashboard/Dashboard/SellOrderForm";
import { fetchRentDataByYear } from "../../slices/rentSlice";



const PropertyDetails = () => {
    const params = useParams();
    const [clickIndex, setclickIndex] = useState();
    const [clickPrice, setclickPrice] = useState();
    const [clickToken, setclickToken] = useState();
    const [tokenTypeAcc, settokenTypeAcc] = useState(null);
    const [buyerAddres, setbuyerAddres] = useState('');
    const [ordersId, setorderId] = useState();
    const [contactModal, setContactModal] = useState(false);
    const [contactsModal, setContactsModal] = useState(false);
    const [loadings, setLoading] = useState(false);
    const [product, setProduct] = useState({});
    const [buyerOrder, setbuyerOrder] = useState({});
    const [textToCopy, setTextToCopy] = useState();
    const [isCopying, setIsCopying] = useState(false);
    const dispatch = useAppDispatch();
    const user = useAppSelector((state) => state.auth);
    const { address, isConnected } = useAccount();
    const { disconnect } = useDisconnect();
    const { Indata: datas } = useAppSelector((state) => state.rent);


    const SellModleData = (_index, _price, _token, _type, _add, _id) => {
        setContactModal(true);
        setclickIndex(_index);
        setclickPrice(_price);
        setclickToken(_token);
        settokenTypeAcc(_type);
        setbuyerAddres(_add);
        setorderId(_id)
    }
    const handleButtonClick = () => {
        window.open(`https://sepolia.arbiscan.io/address/${product.uid}`, '_blank');
        // window.location.href = `https://sepolia.arbiscan.io/address/${product.uid}`;
    };


    const BuyModleData = (_index, _price, _token, _add, _id) => {
        setContactsModal(true);
        setclickIndex(_index);
        // setclickData(_data);
        setclickPrice(_price);
        setclickToken(_token);
        setbuyerAddres(_add);
        setorderId(_id);
    }

    useEffect(() => {
        dispatch(fetchRentDataByYear(
            {
                "propertyId": params.id,
                "year": new Date().getFullYear(),
            }))
    }, [params.id])


    const handleDisconnect = async () => {
        if (isConnected && address) {
            console.log(`Disconnecting wallet with address: ${address}`);

            // Call the backend to mark the wallet as inactive
            try {
                const response = await axios.patch(
                    `${url}/users/wallet/disconnect/${user._id}`,
                    {
                        walletAddress: address, // Send the address to mark inactive
                    }
                );
                console.log(response.data.message);
            } catch (error) {
                console.error("Error disconnecting wallet:", error);
            }
        }
        disconnect(); // Call disconnect function from WAGMI
    };

    useEffect(() => {
        const fetchProduct = async () => {
            try {
                setLoading(true);
                const res = await axios.get(`${url}/products/find/${params.id}`);
                setProduct(res.data);
                setLoading(false);
            } catch (err) {
                console.log(err);
            }
        };
        fetchProduct();
    }, [params.id]);

    useEffect(() => {
        if (product.uid) {
            const fetchorder = async () => {
                try {
                    const dat = await axios.get(`${url}/buyerOrder/find/${product.uid}`);
                    setbuyerOrder(dat.data);
                } catch (err) {
                    console.log(err);
                }
            };
            fetchorder();
        }
    }, [product.uid]);


    const convertTime = function (timestamp, separator) {
        const pad = function (input) { return input < 10 ? "0" + input : input };
        const date = timestamp ? new Date(timestamp * 1000) : new Date();
        return [
            pad(date.getHours()),
            pad(date.getMinutes()),
            pad(date.getSeconds())
        ].join(typeof separator !== 'undefined' ? separator : ':');
    }

    const DashboardComboChart = loadable(() =>
        pMinDelay(import("./Dashboard/Dashboard/DashboardComboChart"), 1000)
    );

    useEffect(() => {
        setTextToCopy(product.escrowAccount);
    }, [product.escrowAccount]);


    const copyToClipboard = () => {
        navigator.clipboard.writeText(textToCopy)
            .then(() => {
                setIsCopying(true);
            })
            .catch((err) => {
                setIsCopying(false);
            });
    };

    return (
        <div style={{ backgroundColor: "#F5F2FD" }}>
            <MarketNav />
            <div className="body-content" style={{ minHeight: window.screen.height - 45 }}>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-8">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card bubles">
                                        <div className="card-body">
                                            <div className="buy-coin bubles-down">
                                                <h1>{product.name}</h1>
                                                <p>{product.propaddress} {product.location}</p>
                                                <h1 className="fs-20"> Token Price: <span
                                                    style={{
                                                        color: "#374557",
                                                        fontSize: "30px"
                                                    }}>
                                                    {product ? <>${(product.tokenPrice / 1e8).toFixed(2)}</> : <>$0.00</>}
                                                </span></h1>
                                                <Button className="me-2" variant="facebook"
                                                    style={{ backgroundColor: "#9568FF" }} onClick={handleButtonClick}>
                                                    Arbiscan{" "}
                                                    <span className="btn-icon-end">
                                                        <img src={arbi} length={"21px"} width={"21px"} className="img-fluid" alt="" />
                                                    </span>
                                                </Button>
                                                <div className="coin-img">
                                                    <img src={product.image?.url} className="img" alt="" />
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-header border-0 align-items-start flex-wrap pb-0">
                                            <div>
                                                <h2 className="heading">
                                                    Market Capitalization:
                                                    <span style={{ fontSize: "25px" }}>
                                                        {""} {product ? <>${(((product.tokenPrice) / 1e8) * ((product.tokenBlance) / 1e18)).toFixed(2)}</> : <>$0.00</>}
                                                    </span>
                                                </h2>
                                                <div className="market-data">
                                                    <div className="income data">
                                                        <span>Token Balance</span>
                                                        <h4>{product ? <>{((product.tokenBlance) / 1e18).toFixed(3)}</> : <>0.00</>}</h4>
                                                    </div>
                                                    <div className="price data">
                                                        <span>PlateForm fee</span>
                                                        <h4>{((product.buySellingFee) / 1e8)} <sub>- 0,5%</sub></h4>
                                                    </div>
                                                    <div className="rate data">
                                                        <span>Sold Token</span>
                                                        <h4>{product ? <>{((product.totalSupply - product.tokenBlance) / 1e18).toFixed(3)}</> : <>0.00</>}</h4>
                                                    </div>
                                                    <div className="rate data">
                                                        <span>Annual CoC</span>
                                                        {datas &&
                                                            datas
                                                                ?.filter((key) => key.propertyId == product._id)
                                                                .map((items) => (
                                                                    <div key={items._id}>
                                                                        {items.annualCoC > 0 ? <h4>{(items.annualCoC).toFixed(1)}%</h4> : <>0.00%</>}
                                                                    </div>
                                                                ))
                                                        }
                                                    </div>
                                                    <div className="volume data">
                                                        <span>Projected Rental Yield</span>
                                                        {datas &&
                                                            datas
                                                                ?.filter((key) => key.propertyId == product._id)
                                                                .map((items) => (
                                                                    <div key={items._id}>
                                                                        {items.rentalYield > 0 ? <h4>{(items.rentalYield).toFixed(1)}%</h4> : <>0.00%</>}
                                                                    </div>
                                                                ))
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <DashboardComboChart />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="profile-tab">
                                                <div className="custom-tab-1">
                                                    <div className="profile-personal-info">
                                                        <h4 className="text-primary mb-4">
                                                            Property Information
                                                        </h4>
                                                        <div className="row mb-2">
                                                            <div className="col-3">
                                                                <h5 className="f-w-500"> Property Type<span className="pull-right">:</span></h5>
                                                            </div>
                                                            <div className="col-9">
                                                                <span>{product.propertytype}</span>
                                                            </div>
                                                        </div>
                                                        <div className="row mb-2">
                                                            <div className="col-3">
                                                                <h5 className="f-w-500">Full Address<span className="pull-right">:</span></h5>
                                                            </div>
                                                            <div className="col-9">
                                                                <span>{product.propaddress}</span>
                                                            </div>
                                                        </div>
                                                        <div className="row mb-2">
                                                            <div className="col-3">
                                                                <h5 className="f-w-500">Bedroom<span className="pull-right">:</span></h5>
                                                            </div>
                                                            <div className="col-9">
                                                                <span>{product.bedroom}</span>
                                                            </div>
                                                        </div>
                                                        <div className="row mb-2">
                                                            <div className="col-3">
                                                                <h5 className="f-w-500">Bathroom<span className="pull-right">:</span></h5>
                                                            </div>
                                                            <div className="col-9">
                                                                <span>{product.bathroom}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="profile-about-me">
                                                        <div className="pt-4 border-bottom-1 pb-3">
                                                            <h4 className="text-primary">Details</h4>
                                                            <p className="mb-2">
                                                                {product.desc}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="profile-skills mb-5">
                                                        <h4 className="text-primary mb-2">Blockchain</h4>
                                                        <Link className="btn btn-primary light btn-xs mb-1 me-1"> Arbitrium</Link>
                                                        <Link className="btn btn-primary light btn-xs mb-1 me-1" > USDT</Link>
                                                        <Link className="btn btn-primary light btn-xs mb-1 me-1" > USDC</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4">
                            <div className="row">
                                <div className="col-xl-12 col-sm-6">
                                    <div className="card h-auto">
                                        <div className="card-body px-0 pt-1">
                                            <Tab.Container defaultActiveKey="Navbuy">
                                                <div className="">
                                                    <div className="buy-sell">
                                                        <Nav className="nav nav-tabs" role="tablist">
                                                            <Nav.Link as="button" className="nav-link" eventKey="Navbuy" type="button">
                                                                market order</Nav.Link>
                                                            <Nav.Link as="button" className="nav-link" eventKey="Navsell" type="button">
                                                                limit order</Nav.Link>
                                                        </Nav>
                                                    </div>
                                                    <Tab.Content  >
                                                        <Tab.Pane eventKey="Navbuy" >
                                                            <Tab.Container defaultActiveKey="Navbuymarket">
                                                                <div className="limit-sell">
                                                                    <Nav className="nav nav-tabs" id="nav-tab3" role="tablist">
                                                                        {isConnected == true ? (
                                                                            <>
                                                                                <Nav.Link onClick={copyToClipboard}>
                                                                                    <span className=" spinner-grow text-success spinner-grow-sm mb-1" role="status"><span className="visually-hidden">Loading...</span></span>
                                                                                    {""} {(product.escrowAccount)?.substring(0, 10) + "...."}
                                                                                    {""}   {isCopying ? (
                                                                                        <>
                                                                                            <RiFileCopyLine /> <IoCheckmarkDone />
                                                                                        </>
                                                                                    ) : (
                                                                                        <RiFileCopyLine />
                                                                                    )}
                                                                                </Nav.Link>
                                                                                <Nav.Link onClick={() => handleDisconnect()} as="button" eventKey="Navbuylimit" type="button"
                                                                                    style={{ backgroundColor: "#9568FF", color: "white", borderRadius: "8px" }}
                                                                                >
                                                                                    Disconnect
                                                                                    <img
                                                                                        src={wal}
                                                                                        alt="wallets"
                                                                                        height="15"
                                                                                        width="15"
                                                                                        style={{ marginLeft: "10px" }}
                                                                                    />
                                                                                </Nav.Link>
                                                                            </>
                                                                        ) : ""}
                                                                    </Nav>
                                                                </div>
                                                                <Tab.Content id="nav-tabContent1">
                                                                    <Tab.Pane eventKey="Navbuymarket"></Tab.Pane>
                                                                    <Tab.Pane eventKey="Navbuylimit"></Tab.Pane>
                                                                </Tab.Content>
                                                                <div className="sell-element">
                                                                    <OrderFormss prop={product} />
                                                                </div>
                                                            </Tab.Container>
                                                        </Tab.Pane>
                                                        <Tab.Pane eventKey="Navsell">
                                                            <Tab.Container defaultActiveKey="Navsellmarket">
                                                                <div className="limit-sell">
                                                                    <Nav className="nav nav-tabs">
                                                                        <Nav.Link as="button" eventKey="Navsellmarket" type="button">buy</Nav.Link>
                                                                        <Nav.Link as="button" eventKey="Navselllimit" type="button" >sell</Nav.Link>
                                                                    </Nav>
                                                                </div>
                                                                <Tab.Content id="nav-tabContent2">
                                                                    <Tab.Pane id="Navsellmarket" > </Tab.Pane>
                                                                    <Tab.Pane id="Navselllimit" > </Tab.Pane>
                                                                </Tab.Content>
                                                                <div className="sell-element">
                                                                    {/* <BuyOrderForm prop={product} /> */}
                                                                    {/* <SellOrderForm prop={product} /> */}
                                                                    <Tab.Content>
                                                                        <Tab.Pane eventKey="Navsellmarket"><BuyOrderForm prop={product} /></Tab.Pane>
                                                                        <Tab.Pane eventKey="Navselllimit"><SellOrderForm prop={product} /></Tab.Pane>
                                                                    </Tab.Content>
                                                                </div>
                                                            </Tab.Container>
                                                        </Tab.Pane>
                                                    </Tab.Content>
                                                </div>
                                            </Tab.Container>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-12 col-sm-6">
                                    <div className="card">
                                        <div className="card-header py-2">
                                            <h2 className="heading">Open Order <span>({product.name} / USDT or USDC)</span></h2>
                                        </div>
                                        <div className="card-body pt-0 pb-3 px-2">
                                            <Tab.Container defaultActiveKey="Openorder">
                                                <nav className="buy-sell style-1">
                                                    <Nav className=" nav-tabs" id="nav-tab1" role="tablist">
                                                        <Nav.Link as="button" className="nav-link " eventKey="Openorder" type="button" >Buy Tokens</Nav.Link>
                                                        <Nav.Link as="button" className="nav-link" eventKey="Orderhistory" type="button" >Sell Tokens</Nav.Link>
                                                    </Nav>
                                                </nav>
                                                <div className="height450 dz-scroll">
                                                    <Tab.Content>
                                                        <Tab.Pane eventKey="Openorder">
                                                            <div className="card list-table  overflow-hidden mt-3">
                                                                <div className="card-body  previews-info-list">
                                                                    <span>
                                                                        <p className="mb-2 fs-13"
                                                                            style={{ fontSize: "10px" }}
                                                                        ><i className="fa fa-caret-up scale5 me-2 text-success" aria-hidden="true"></i>
                                                                            Accepet Crypto <span className='text-success'> USDT/USDC</span>
                                                                        </p>
                                                                        <h4 className="heading mb-0">
                                                                            50.32
                                                                            {/* {Number(item.Price_of_Tokens / 1e8).toFixed(2)} */}
                                                                            <span>(USD) </span></h4>
                                                                        <h4 className="fs-13"
                                                                            style={{ fontSize: "15px" }}
                                                                        >Tokens: {""} <span className='text-primary' style={{ fontSize: "25px" }}
                                                                        >{""}
                                                                                {/* {Number(item.Number_of_Tokens / 1e18).toFixed(2)}  */}
                                                                                12.34
                                                                            </span><span style={{ fontSize: "9px" }}> For Sell</span></h4>
                                                                    </span>

                                                                    <span className="text-end">
                                                                        <p className="mb-1" style={{ fontSize: "10px" }}>
                                                                            Expire in:<br /> 12:300
                                                                            {/* {convertTime(item.expireIn - Math.round(+new Date() / 1000))} */}
                                                                        </p>
                                                                        <button className="btn btn-success text-end btn-sm"
                                                                        // onClick={() => SellModleDatas(index, Number(item.Price_of_Tokens),
                                                                        //     Number(item.Number_of_Tokens / 1e18).toFixed(2), item.SellersAddress, item._id)}
                                                                        >Buy
                                                                        </button>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="card list-table  overflow-hidden mt-3">
                                                                <div className="card-body  previews-info-list">
                                                                    <span>
                                                                        <p className="mb-2 fs-13"
                                                                            style={{ fontSize: "10px" }}
                                                                        ><i className="fa fa-caret-up scale5 me-2 text-success" aria-hidden="true"></i>
                                                                            Accepet Crypto <span className='text-success'> USDT/USDC</span>
                                                                        </p>
                                                                        <h4 className="heading mb-0">
                                                                            50.32
                                                                            {/* {Number(item.Price_of_Tokens / 1e8).toFixed(2)} */}
                                                                            <span>(USD) </span></h4>
                                                                        <h4 className="fs-13"
                                                                            style={{ fontSize: "15px" }}
                                                                        >Tokens: {""} <span className='text-primary' style={{ fontSize: "25px" }}
                                                                        >{""}
                                                                                {/* {Number(item.Number_of_Tokens / 1e18).toFixed(2)}  */}
                                                                                12.34
                                                                            </span><span style={{ fontSize: "9px" }}> For Sell</span></h4>
                                                                    </span>

                                                                    <span className="text-end">
                                                                        <p className="mb-1" style={{ fontSize: "10px" }}>
                                                                            Expire in:<br /> 12:300
                                                                            {/* {convertTime(item.expireIn - Math.round(+new Date() / 1000))} */}
                                                                        </p>
                                                                        <button className="btn btn-success text-end btn-sm"
                                                                        // onClick={() => SellModleDatas(index, Number(item.Price_of_Tokens),
                                                                        //     Number(item.Number_of_Tokens / 1e18).toFixed(2), item.SellersAddress, item._id)}
                                                                        >Buy
                                                                        </button>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="card list-table  overflow-hidden mt-3">
                                                                <div className="card-body  previews-info-list">
                                                                    <span>
                                                                        <p className="mb-2 fs-13"
                                                                            style={{ fontSize: "10px" }}
                                                                        ><i className="fa fa-caret-up scale5 me-2 text-success" aria-hidden="true"></i>
                                                                            Accepet Crypto <span className='text-success'> USDT/USDC</span>
                                                                        </p>
                                                                        <h4 className="heading mb-0">
                                                                            50.32
                                                                            {/* {Number(item.Price_of_Tokens / 1e8).toFixed(2)} */}
                                                                            <span>(USD) </span></h4>
                                                                        <h4 className="fs-13"
                                                                            style={{ fontSize: "15px" }}
                                                                        >Tokens: {""} <span className='text-primary' style={{ fontSize: "25px" }}
                                                                        >{""}
                                                                                {/* {Number(item.Number_of_Tokens / 1e18).toFixed(2)}  */}
                                                                                12.34
                                                                            </span><span style={{ fontSize: "9px" }}> For Sell</span></h4>
                                                                    </span>

                                                                    <span className="text-end">
                                                                        <p className="mb-1" style={{ fontSize: "10px" }}>
                                                                            Expire in:<br /> 12:300
                                                                            {/* {convertTime(item.expireIn - Math.round(+new Date() / 1000))} */}
                                                                        </p>
                                                                        <button className="btn btn-success text-end btn-sm"
                                                                        // onClick={() => SellModleDatas(index, Number(item.Price_of_Tokens),
                                                                        //     Number(item.Number_of_Tokens / 1e18).toFixed(2), item.SellersAddress, item._id)}
                                                                        >Buy
                                                                        </button>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </Tab.Pane>
                                                        <Tab.Pane eventKey="Orderhistory" >
                                                            {buyerOrder.length > 0 ? (
                                                                <>
                                                                    {buyerOrder && buyerOrder.map((item, index) => (
                                                                        <div className="card list-table  overflow-hidden mt-3" key={index}>
                                                                            <div className="card-body  previews-info-list">
                                                                                <span>
                                                                                    <p className="mb-2 fs-13"
                                                                                        style={{ fontSize: "10px" }}
                                                                                    ><i className="fa fa-caret-up scale5 me-2 text-success" aria-hidden="true"></i>
                                                                                        Accepet Crypto<span className='text-success'>
                                                                                            {item.Type_Of_Currency == 0 ? (<> USDT</>) : <> USDC</>}
                                                                                        </span>
                                                                                    </p>
                                                                                    <h4 className="heading mb-0">
                                                                                        <span style={{ fontSize: "20px" }}>$</span>{Number(item.Price_of_Tokens)}
                                                                                    </h4>
                                                                                    <h4 className="fs-13"
                                                                                        style={{ fontSize: "15px" }}
                                                                                    >Tokens: {""} <span className='text-primary' style={{ fontSize: "25px" }}
                                                                                    >{""} {Number(item.Number_of_Tokens)}
                                                                                        </span><span style={{ fontSize: "9px" }}> Avaliable</span></h4>
                                                                                </span>
                                                                                <span className="text-end">
                                                                                    <p className="mb-1" style={{ fontSize: "10px" }}>Expire in:<br />
                                                                                        {convertTime(item.expireIn - Math.round(+new Date() / 1000))}
                                                                                    </p>
                                                                                    <button className="btn btn-danger text-end btn-sm"
                                                                                        onClick={() => SellModleData(index, Number(item.Price_of_Tokens),
                                                                                            Number(item.Number_of_Tokens / 1e18).toFixed(2), item.Type_Of_Currency,
                                                                                            item.BuyersAddress, item._id)}
                                                                                    >Sell
                                                                                    </button>
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </>
                                                            ) : ""}
                                                        </Tab.Pane>
                                                    </Tab.Content>
                                                </div>
                                            </Tab.Container>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal className="modal fade" id="exampleModal" centered show={contactModal} onHide={setContactModal}>
                <div className="modal-content">
                    <div className="modal-header">
                        <a id="exampleModalLabel" style={{ fontSize: "13px" }}>
                            Price: <span className="modal-title text-danger" style={{ fontSize: "20px" }}
                            >{Number(clickPrice / 1e8).toFixed(2)} $</span></a>
                        <button type="button" className="btn-close"
                            onClick={() => setContactModal(false)}></button>
                    </div>
                    <form>
                        <div className="modal-body">
                            <div className="sell-blance">
                                <p className="mb-2 fs-13"
                                    style={{ fontSize: "10px" }}
                                ><i className="fa fa-caret-up scale5 me-2 text-danger" aria-hidden="true"></i>
                                    Accepet Crypto <span className='text-danger'>
                                        {tokenTypeAcc == 0 ? (<> USDT</>) : <> USDC</>}
                                    </span>
                                </p>
                                <div className="input-group">
                                    {tokenTypeAcc == 0 ?
                                        (
                                            <input
                                                type="text"
                                                className="form-control"
                                                value="USDT"
                                            />
                                        ) : (
                                            <input
                                                type="text"
                                                className="form-control"
                                                value="USDC"
                                            />
                                        )}
                                    <span className="input-group-text">
                                        {tokenTypeAcc == 0 ?
                                            (<a className="text-black"> USDT</a>) : (
                                                <a className="text-black"> USDC</a>
                                            )}
                                    </span>
                                </div>
                                <p className="mb-2 fs-13"
                                    style={{ fontSize: "12px" }}
                                >
                                    <i className="fa fa-caret-up scale5 me-2 text-secondary " aria-hidden="true"></i>
                                    {clickToken} <span className='text-danger'> Avaliable Tokens</span>
                                </p>
                                <div className="input-group">
                                    <input
                                        type="text"
                                        className="form-control"
                                        // onChange={(e) => settokenQuant(e.target.value)}
                                        placeholder={"Enter Quantity"}
                                    />
                                    <span className="input-group-text">
                                        {/* {Number(checkNumbers / 1e6).toFixed(3)} */}
                                        {tokenTypeAcc == 0 ?
                                            (<a className="text-black"> -USDT</a>) : (
                                                <a className="text-black"> -USDC</a>
                                            )}
                                    </span>
                                </div>
                                {/* {tokenQuant > clickToken ? (
                                    <a className="text-danger">Limit is exceeded </a>
                                ) : ""} */}
                            </div>
                        </div>
                    </form>
                    <div className="modal-footer mt-0">
                        <button type="button" className="btn btn-secondary" style={{ fontSize: "14px" }}>
                            Please read the terms & condition before placing an order.
                        </button>
                        <button type="button" className="btn btn-danger text-end btn-sm"
                        // onClick={() => Sell()}
                        >Sell</button>
                    </div>
                </div>
            </Modal>
            <Footr />
        </div>

    )
}
export default PropertyDetails;