// import { CONTRACT_ADDRESS } from '../jsx/components/contract/RealEstate';
import Contract_ABI  from '../jsx/components/contract/SecondaryDAO.json';
import USDT_ABI  from '../jsx/components/contract/USDT.json';
import USDC_ABI  from '../jsx/components/contract/USDC.json';
// import SEDT_ABI  from '../jsx/components/contract/SEDT.json';
import WhiteList_ABI from '../jsx/components/contract/WhiteList.json';
// import {CONTRACT_ABIS } from '../jsx/components/contract/property';
// import {USDT_ADDRESS, USDT_ABI } from '../jsx/components/contract/USDT';
// import {USDC_ADDRESS, USDC_ABI } from '../jsx/components/contract/USDC';
// import {ESCROW_ADDRESS, ESCROW_ABI } from '../jsx/components/contract/Escrow';
// import {VOTTING_ADDRESS, VOTTING_ABI } from '../jsx/components/contract/Voting';
import Web3 from 'web3';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {stringify} from 'flatted';
// import WalletConnectProvider from "@walletconnect/web3-provider";
// import WalletConnectProvider from '@walletconnect/web3-provider/dist/umd/index.min.js';
// import QRCodeModal from '@walletconnect/qrcode-modal/dist/umd/index.min.js';
// import axios from "axios";
// import { url, setHeaders } from "./api";


/**
 * THIS IS EXAMPLE CODE THAT USES HARDCODED VALUES FOR CLARITY.
 * THIS IS EXAMPLE CODE THAT USES UN-AUDITED CODE.
 * DO NOT USE THIS CODE IN PRODUCTION.
 */


//  const aggregatorV3InterfaceABI = [
//    {
//      inputs: [],
//      name: "decimals",
//      outputs: [{ internalType: "uint8", name: "", type: "uint8" }],
//      stateMutability: "view",
//      type: "function",
//    },
//    {
//      inputs: [],
//      name: "description",
//      outputs: [{ internalType: "string", name: "", type: "string" }],
//      stateMutability: "view",
//      type: "function",
//    },
//    {
//      inputs: [{ internalType: "uint80", name: "_roundId", type: "uint80" }],
//      name: "getRoundData",
//      outputs: [
//        { internalType: "uint80", name: "roundId", type: "uint80" },
//        { internalType: "int256", name: "answer", type: "int256" },
//        { internalType: "uint256", name: "startedAt", type: "uint256" },
//        { internalType: "uint256", name: "updatedAt", type: "uint256" },
//        { internalType: "uint80", name: "answeredInRound", type: "uint80" },
//      ],
//      stateMutability: "view",
//      type: "function",
//    },
//    {
//      inputs: [],
//      name: "latestRoundData",
//      outputs: [
//        { internalType: "uint80", name: "roundId", type: "uint80" },
//        { internalType: "int256", name: "answer", type: "int256" },
//        { internalType: "uint256", name: "startedAt", type: "uint256" },
//        { internalType: "uint256", name: "updatedAt", type: "uint256" },
//        { internalType: "uint80", name: "answeredInRound", type: "uint80" },
//      ],
//      stateMutability: "view",
//      type: "function",
//    },
//    {
//      inputs: [],
//      name: "version",
//      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
//      stateMutability: "view",
//      type: "function",
//    },
//  ]
//  const USDCaddr = "0x0153002d20B96532C639313c2d54c3dA09109309";
//  const USDTaddr = "0x80EDee6f667eCc9f63a0a6f55578F870651f06A4";

   

export const initialState = {
    status: null,
    web3: null,
    contract: null,
    socketContract: null,
    accounts: [],
    balance: [],
    Provider: null,
    web3loadingerror: null,
    propContracts:[],
    UsdtContract: null,
    UsdcContract: null,
    EscrowContract: null,
    VotingContract: null,
    whiteListContract: null,
    // USDTpriceFeed: null,
    // USDCpriceFeed: null,
    balance: [],
}

const CONTRACT_ADDRESS="0xdfD5F000EC5b410dEEf294d75ee1078dAc3E458F";
// const VOTTING_ADDRESS="0xd937a548cf730280Da24bD765c894Cec1C468465";
const WhiteList_ADDRESS="0x6f463fd67F7e6742bA8C636879De8001df52FA2b";
const USDTADDRESS = "0x5417928Ef1Ab9e341E92872b3995ed03cb3f7e34";
const USDCADDRESS = "0x67B77178515655715C0fD328B057aD318B76B6Bb";
// const Infura = 'https://arbitrum-sepolia.infura.io/v3/61679e33f48747afac34c6aabc66e5c4'
// const WhiteList = process.env.WhiteList_ADDRESS;




export const loadBlockchain = createAsyncThunk("loadBlockchain", async (_, thunkAPI) => {
    try {
        if (Web3.givenProvider && Web3.givenProvider.chainId === Web3.utils.toHex(421614)) {
            const Provider = Web3.givenProvider;
            // localStorage.setItem("Providers", stringify(Provider));
            await Web3.givenProvider.enable();
            const web3 = new Web3(Web3.givenProvider);
            // localStorage.setItem("Web3s", stringify(web3));
            const contract = new web3.eth.Contract(Contract_ABI, CONTRACT_ADDRESS);
            // console.log("contract", contract)
            const accounts = await web3.eth.getAccounts();
            localStorage.setItem("accounts", accounts);
            // console.log(`Wallet address ${accounts} stored on local storage.`);
            const balance = await web3.eth.getBalance(accounts[0]);
            const UsdtContract = new web3.eth.Contract(USDT_ABI, USDTADDRESS);
            const UsdcContract = new web3.eth.Contract(USDC_ABI, USDCADDRESS);
            const whiteListContract = new web3.eth.Contract(WhiteList_ABI, WhiteList_ADDRESS);
            //web3 Socket
            // const web3Socket = new Web3(new Web3.providers.WebsocketProvider(
            //     `wss://goerli.infura.io/ws/v3/b0b0d100567e4e59bb2bab1a2c353381`
            // ))

            // const socketContract = new web3Socket.eth.Contract(CONTRACT_ABI, CONTRACT_ADDRESS)
            return {
                web3,
                balance,
                accounts,
                Provider,
                contract,
                UsdtContract,
                UsdcContract,
                whiteListContract,
                // socketContract,
            }
        }
        else {
            try {
                await Web3.givenProvider.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: Web3.utils.toHex(421614) }]
                });
            } catch (error) {
                if(error.code === 4902 ){
                await window.ethereum.request({
                    method: 'wallet_addEthereumChain',
                    params: [
                      {
                        chainId: Web3.utils.toHex(421614),
                        chainName: "Sepolia test network",
                        // rpcUrls: ["https://arbitrum-sepolia.blockpi.network/v1/rpc/public"],
                        rpcUrls: ["https://sepolia-rollup.arbitrum.io/rpc"],
                        nativeCurrency: {
                            name:"ETH",
                            symbol:"ETH",
                            decimals: 18,
                        },
                        blockExplorerUrls: ["https://sepolia.arbiscan.io/"],
                        // blockExplorerUrls: ["https://sepolia-explorer.arbitrum.io"],
                      },
                    ],
                  });
                }
            }
            return {
                web3loadingerror: 'errorloading'

            }

        }

    } catch (error) {
        console.log('Network ID error', error)

    }
});






export const updatAccount = createAsyncThunk("updatAccount", async (data, thunkAPI) => {
    try {
        let accounts = data
        localStorage.setItem("accounts", data)
        return {
            accounts,
        }
    } catch (error) {
        console.log('error', error)

    }
});


const web3ConnectSlice = createSlice({
    name: 'web3Connect',
    initialState,
    reducers: {},
    extraReducers: {
        [loadBlockchain.pending.toString()]: (state, { payload }) => {
            state.status = "pending"
        },
        [loadBlockchain.fulfilled.toString()]: (state, { payload }) => {
            state.web3 = payload?.web3;
            // localStorage.setItem("Web3s", stringify(state.web3));
            state.contract = payload?.contract;
            state.accounts = payload?.accounts;
            state.balance = payload?.balance;
            state.Provider = payload?.Provider;
            // localStorage.setItem("Providers", stringify(state.Provider));
            // state.socketContract = payload?.socketContract;
            state.UsdtContract = payload?.UsdtContract;
            state.UsdcContract = payload?.UsdcContract;
            state.whiteListContract = payload?.whiteListContract;
            // state.USDTpriceFeed = payload?.USDTpriceFeed;
            // state.USDCpriceFeed = payload?.USDCpriceFeed;
            // state.VotingContract = payload?.VotingContract;
            state.status = "success";
        },
        [loadBlockchain.rejected.toString()]: (state, { payload }) => {
            state.status = "rejected";
        },

        // [loadWalletConnect.fulfilled.toString()]: (state, { payload }) => {
        //     state.web3 = payload?.web3;
        //     state.contract = payload?.contract;
        //     state.accounts = payload?.accounts;
        //     state.UsdtContract = payload?.UsdtContract;
        //     state.UsdcContract = payload?.UsdcContract;
        //     // state.socketContract = payload?.socketContract;
        // },
        [updatAccount.fulfilled.toString()]: (state, { payload }) => {
            state.accounts = payload?.accounts;
        },
        // [loadContracts.fulfilled.toString()]: (state, { payload }) => {
        //     state.propContracts = payload?.propContracts;
        // }
    }
})

export const web3Reducer = web3ConnectSlice.reducer;
