import { createSlice } from '@reduxjs/toolkit';


export const initialState = {
    Type: [],
}


const propfilterReducer = createSlice({
    name: 'propfilterReducer',
    initialState,
    reducers: {
        FILTER(state , action){
            state.Type = action.payload;
        },
        
    },

})
export const {FILTER,CHECK} = propfilterReducer.actions;

export const propfilterReducers = propfilterReducer.reducer;



