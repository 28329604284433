import { lazy, Suspense, useEffect } from 'react';
import { loadUser } from "./slices/authSlice";
/// Components
import Index from "./jsx";
import {useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom';

/// Style
import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";

const SignUp = lazy(() => import('./jsx/pages/Registration'));
const ForgotPassword = lazy(() => import('./jsx/pages/ForgotPassword'));
const Login = lazy(() => import('./jsx/pages/Login'));

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();

    return (
      <Component
        {...props}
        router={{ location, navigate, params }}
      />
    );
  }

  return ComponentWithRouterProp;
}


function App() {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadUser(null));
  }, [dispatch]);


  let routeblog = (

    <Routes>
      <Route path='/login' element={<Login />} />
      <Route path='/page-register' element={<SignUp />} />
      <Route path='/page-forgot-password' element={<ForgotPassword />} />
      
    </Routes>
  );

  // if (auth._id) {
    return (
      <>
        <Suspense fallback={
          <div id="preloader">
            <div className="sk-three-bounce">
              <div className="sk-child sk-bounce1"></div>
              <div className="sk-child sk-bounce2"></div>
              <div className="sk-child sk-bounce3"></div>
            </div>
          </div>
        }
        >
          <Index />
          {routeblog}
        </Suspense>
      </>
    );

  // } else {
  //   return (
  //     <div className="vh-100">
  //       <Suspense fallback={
  //         <div id="preloader">
  //           <div className="sk-three-bounce">
  //             <div className="sk-child sk-bounce1"></div>
  //             <div className="sk-child sk-bounce2"></div>
  //             <div className="sk-child sk-bounce3"></div>
  //           </div>
  //         </div>
  //       }
  //       >
  //         {routeblog}
  //       </Suspense>
  //     </div>
  //   );
  // }
};

export default withRouter(App);

