import { combineReducers } from "@reduxjs/toolkit";
import { web3Reducer } from "../slices/web3ContractSlice";
import productsReducer from "../slices/productsSlice";
import cartReducer from "../slices/cartSlice";
import authReducer from "../slices/authSlice";
import { productsApi } from "../slices/productsApi";
import UsersSlice from "../slices/UsersSlice";
import ordersSlice from "../slices/ordersSlice";
import personaldbReducer from "../slices/personalSlice";
import  {personaldbApi}  from "../slices/personalApi";
import propertiesdbReducer from "../slices/propertySlice";
import {propertiesdbApi} from "../slices/propertyApi";
import { adminReducers } from "../slices/adminEventSlice";
import { propDetailReducers } from "../slices/detailofPropertySlice";
import { filterReducers } from "../slices/filter";
import { propLLCApi } from "../slices/llcApi";
import propLLCReducer from "../slices/llcSlice";
import { blogApi } from "../slices/blogApi";
import {blogdbReducer} from "../slices/blogSlice";
import { propAddressReducers } from "../slices/propertyAddressSlice";
import { expenseApi } from "../slices/expenseAPI";
import expenseSlice from "../slices/expenseSlice";
import { rentApi } from "../slices/rentAPI";
import rentSlice from "../slices/rentSlice";
import { propfilterReducers } from "../slices/propFilter";
import { otpGenerateReducer } from "../slices/otpGenerate";
import {otpGenerateApi} from "../slices/otpGenerateApi";
import resetPasswordReducer  from "../slices/resetPassword";
import { resetPasswordApi } from "../slices/resetPasswordApi";

const parentReducer = combineReducers({
    sendemail: otpGenerateReducer,
    resetPassword: resetPasswordReducer,
    web3Connect : web3Reducer,
    PropAddress: propAddressReducers,
    AdminAddress: adminReducers,
    DetailsofProperty: propDetailReducers,
    FiterProperty: filterReducers,
    FilterTypeProp: propfilterReducers,
    products: productsReducer,
    users: UsersSlice,
    orders: ordersSlice,
    cart: cartReducer,
    auth: authReducer,
    personaldb: personaldbReducer,
    propertiesdb:propertiesdbReducer,
    propLLC:propLLCReducer,
    blogdb:blogdbReducer,
    expense: expenseSlice,
    rent: rentSlice,
    [productsApi.reducerPath]: productsApi.reducer,
    [personaldbApi.reducerPath]: personaldbApi.reducer,
    [propertiesdbApi.reducerPath]: propertiesdbApi.reducer,
    [propLLCApi.reducerPath]: propLLCApi.reducer,
    [blogApi.reducerPath]: blogApi.reducer,
    [expenseApi.reducerPath]: expenseApi.reducer,
    [rentApi.reducerPath]: rentApi.reducer,
    [otpGenerateApi.reducerPath]:otpGenerateApi.reducer,
    [resetPasswordApi.reducerPath]:resetPasswordApi.reducer,

})

export default parentReducer;