import React, { useEffect, useState } from "react";
import { OtpGenerated } from "../../slices/otpGenerate";
import Spinner from "react-bootstrap/Spinner";
import { useAppDispatch, useAppSelector } from "../../reducer/store";
import { Link, useNavigate } from "react-router-dom";
import Mnavbar from "../layouts/Mnavbar";
import Footr from "./Footr";


const EnterEmail = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { code, otpStatus } = useAppSelector((state) => state.sendemail);
  const [user, setUser] = useState({
    email: "",
  });

  useEffect(() => {
    if (otpStatus === "success") {
      navigate("/otpcode");
    }
  }, [navigate, otpStatus]);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(OtpGenerated(user));
  };


  return (
    <>
      <Mnavbar />
      <div className="dz-bnr-inr">
        <div className="body-content" style={{ minHeight: window.screen.height - 45 }}>
          <div className="container-fluid">
            <div className="page-content bg-white mt-5" style={{borderRadius:"20px"}}>
              <section className="content-inner contact-form-wraper style-1">
                <div className="container">
                  <div className="row align-items-center">
                    <div className="col-xl-5 col-lg-5 m-b30 mt-5">
                      <div className="info-box">
                        <div className="info">
                          <h2>Contact Information</h2>
                          <p className="font-18">
                            Fill out the form and our team will get back to you within
                            24 hours.
                          </p>
                        </div>

                        <div className="widget widget_about">
                          <div className="widget widget_getintuch">
                            <ul>
                              <li>
                                <i className="fa fa-phone"></i>
                                <span>+14129513882</span>
                              </li>
                              <li>
                                <i className="fa fa-envelope"></i>
                                <span>
                                  info@secondarydao.com
                                  <br />
                                  secondarydao@gmail.com
                                </span>
                              </li>
                              <li>
                                <i className="fas fa-map-marker-alt"></i>
                                <span>
                                  6 State RD<br />
                                  Suite 117 <br />
                                  Mechanicsburg, PA 17050-7957
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <h4 className="text-primary mb-2">Our Socials</h4>
                        <Link className="btn btn-primary light btn-xs mb-1 me-1 mb-5" target="_blank" href={"https://www.linkedin.com/company/SecondaryDAO/"}> <i className="fa-brands fa-linkedin"></i></Link>
                        <Link className="btn btn-primary light btn-xs mb-1 me-1 mb-5" target="_blank" href={"https://www.twitter.com/secondaryDAO"}> <i className="fa-brands fa-twitter"></i></Link>
                        <Link className="btn btn-primary light btn-xs mb-1 me-1 mb-5" target="_blank" href={"https://www.youtube.com/channel/UCbAfRu0udgs0Ur2LsVXLbyA"}> <i className="fab fa-youtube"></i></Link>
                      </div>
                    </div>

                    <div className="col-xl-7 col-lg-7">
                      <div className="contact-box">
                        <div className="card">
                          <div className="card-body">
                            <div className="mb-4">
                              <h2 className="mb-0">Enter Email</h2>
                            </div>
                            <form className="dzForm" onSubmit={handleSubmit}>
                              <div className="dzFormMsg"></div>
                              <input
                                type="hidden"
                                className="form-control"
                                name="dzToDo"
                                value="Contact"
                              />
                              <div className="row">
                                <div className="mb-3 mb-md-4">
                                  <input
                                    name="dzFirstName"
                                    type="email"
                                    className="form-control"
                                    placeholder="Email"
                                    // onChange={(e) => setemails(e.target.value)} />
                                    onChange={(e) =>
                                      setUser({ ...user, email: e.target.value })
                                    }
                                  />
                                </div>
                                <div className="col-xl-12">
                                  <button
                                    name="submit"
                                    type="submit"
                                    value="Submit"
                                    className="btn btn-primary"
                                  >
                                    {otpStatus === "pending" ? (
                                      <>
                                        <Spinner
                                          animation="border"
                                          variant="secondary"
                                        />
                                      </>
                                    ) : (
                                      "  Submit"
                                    )}
                                  </button>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
      <Footr />
    </>
  );
};

export default EnterEmail;
