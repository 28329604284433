import React, { useEffect, useState, useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from "axios";
import { url, setHeaders } from "./../../../../slices/api";
import walet from '../../../../images/walet.png';
import meta2 from '../../../../images/meta2.png';
import Loader from './Loader';
import { Button, Modal } from 'react-bootstrap';
import { USDT_ADDRESS } from "../../contract/Addresses";
import { USDC_ADDRESS } from "../../contract/Addresses";
import USDT_ABI from '../../contract/USDT.json';
import USDC_ABI from '../../contract/USDC.json';
import { useAppSelector } from "./../../../../reducer/store";
import { projectId } from "../../contract/Addresses";
import { config } from "./../../../../slices/config";
import { readContract, writeContract } from '@wagmi/core';
import { http, createConfig, useAccount, useConnect } from 'wagmi';
import { mainnet, sepolia, arbitrumSepolia, arbitrum } from 'wagmi/chains';
import { coinbaseWallet, injected, walletConnect } from 'wagmi/connectors';


export const configure = createConfig({
	chains: [mainnet, sepolia, arbitrumSepolia, arbitrum],
	connectors: [
		injected(),
		coinbaseWallet(),
		walletConnect({ projectId }),
	],
	transports: {
		[mainnet.id]: http(),
		[sepolia.id]: http(),
		[arbitrumSepolia.id]: http(),
	},
})

const OrderFormss = ({ prop }) => {
	const params = useParams();
	const [USDTprice, setUSDTprice] = useState(0);
	const [SEDTprice, setSEDTprice] = useState(0);
	const [USDCprice, setUSDCprice] = useState(0);
	const [tokenType, settokenType] = useState();
	const [price, setprice] = useState(0);
	const [quantity, setquantity] = useState(0);
	const [checkUSDTTokens, setcheckUSDTTokens] = useState();
	const [loading, setLoading] = useState(false);
	const [contactModal, setContactModal] = useState(false);
	const { address, addresses, isConnected } = useAccount();
	const { connectors, connect } = useConnect();
	const user = useAppSelector((state) => state.auth);

	useEffect(() => {
		if (isConnected && address && addresses?.length && user._id) {
			updateWalletsInDB(addresses, address, user._id);
		}
	}, [isConnected, address, addresses, user._id]);

	const updateWalletsInDB = async (walletAddresses, activeWallet, userID) => {
		try {
			// const response = 
			await axios.patch(
				`${url}/users/wallet/update/${userID}`,
				{
					walletAddresses,
					activeWallet,
				}
			);
			// const data = response.data;

			// if (response.ok) {
			// 	console.log("Wallets updated successfully:", data);
			// } else {
			// 	console.error("Error updating wallets:", data.message);
			// }
		} catch (error) {
			console.error("Failed to update wallets:", error);
		}
	};

	const USDTApproval = async (addres, _tokens) => {
		const result = await writeContract(config, {
			abi: USDT_ABI,
			address: USDT_ADDRESS,
			functionName: 'approve',
			args: [addres, _tokens],
			account: address,
		})
		return result;
	}

	const USDCApproval = async (addres, _tokens) => {
		const result = await writeContract(config, {
			abi: USDC_ABI,
			address: USDC_ADDRESS,
			functionName: 'approve',
			args: [addres, _tokens],
			account: address,
		})
		return result;
	}

	const BuybyUsdt = async () => {
		if (prop.isStartSelling == true) {
			try {
				let tokens = ((Number(prop.tokenPrice) * quantity * 10 ** 6) / (USDTprice)).toFixed(0).toString();
				let fee = ((tokens / (10 ** 10)) * Number(prop.buySellingFee)).toFixed(0).toString();

				setcheckUSDTTokens(Number(tokens) + Number(fee));
				setLoading(true);
				await USDTApproval(prop.escrowAccount, Number(tokens) + Number(fee))
					.then(async (aprv) => {

						console.log("Approval", aprv)
						const QuantNo = Number(quantity * 10 ** 18).toFixed(0).toString();
						await axios.patch(
							`${url}/products/order/${params.id}`,
							{
								"buyer": address,
								"currency": tokenType,
								"tokens": QuantNo,
								"Receivabletokens": tokens,
								"Receivablefee": fee
							},
							setHeaders()
						)
						setLoading(false);
					})
			} catch (error) {
				setLoading(false);
				console.log("First Approve Error", error);
			}
		} else {
			try {
				let tokens = ((Number(price * 1e8) * quantity * 10 ** 6) / (USDTprice)).toFixed(0).toString();
				let fee = ((tokens / (10 ** 10)) * Number(prop.buySellingFee)).toFixed(0).toString();

				setcheckUSDTTokens(Number(tokens) + Number(fee));
				setLoading(true);
				await USDTApproval(prop.escrowAccount, Number(tokens) + Number(fee))
					.then(async (aprv) => {

						console.log("Approval", aprv)
						const QuantNo = Number(quantity * 10 ** 18).toFixed(0).toString();
						await axios.patch(
							`${url}/products/order/${params.id}`,
							{
								"buyer": address,
								"currency": tokenType,
								"tokens": QuantNo,
								"Receivabletokens": tokens,
								"Receivablefee": fee
							},
							setHeaders()
						)
						setLoading(false);
					})
			} catch (error) {
				setLoading(false);
				console.log("First Approve Error", error);
			}
		}
	};

	const BuybyUsdc = async () => {
		try {
			let tokens = ((Number(prop.tokenPrice) * quantity * 10 ** 6) / (USDCprice)).toFixed(0).toString();
			let fee = ((tokens / (10 ** 10)) * Number(prop.buySellingFee)).toFixed(0).toString();
			setLoading(true);
			await USDCApproval(prop.escrowAccount, Number(tokens) + Number(fee))
				.then(async () => {
					const QuantNo = Number(quantity * 10 ** 18).toFixed(0).toString();
					await axios.post(
						`${url}/products/order/${params.id}`,
						{
							"buyer": address,
							"currency": tokenType,
							"tokens": QuantNo,
							"Receivabletokens": tokens,
							"Receivablefee": fee
						},
						setHeaders()
					)
					setLoading(false);

				})
		} catch (error) {
			setLoading(false);
			console.log("Second Approve Error", error);
		}
	};

	useEffect(() => {
		if (USDTprice === 0) {
			const USDTPrice = async () => {
				try {
					await axios.get(`${url}/products/usdt/${params.id}`)
						.then((result) => {
							setUSDTprice(result.data);
						})
				} catch (err) {
					console.log(err);
				}
			};
			USDTPrice();
		}
	},[USDTprice])

	useEffect(() => {
		if (USDCprice === 0) {
			const USDCPrice = async () => {
				try {
					await axios.get(`${url}/products/usdc/${params.id}`)
						.then((roundData) => {
							setUSDCprice(roundData.data);
						})
				} catch (err) {
					console.log(err);
				}
			}
			USDCPrice();
		}
	},[USDCprice])

	const CalculateValue = (_quantity) => {
		// if (prop.isStartSelling == true) {
			if (tokenType == 0) {
				let tokens = ((Number(prop.tokenPrice) * (_quantity) * 1e6) / (USDTprice)).toFixed(0).toString();
				let fee = ((tokens / 1e10) * Number(prop.buySellingFee)).toFixed(0).toString();
				// setCalToken(Number(tokens) + Number(fee))
				const num = Number(tokens) + Number(fee)
				return num;
			} else if (tokenType == 1) {
				let tokens = ((Number(prop.tokenPrice) * (_quantity) * 1e6) / (USDCprice)).toFixed(0).toString();
				let fee = ((tokens / (1e10)) * Number(prop.buySellingFee)).toFixed(0).toString();
				// setCalToken(Number(tokens) + Number(fee))
				const num = Number(tokens) + Number(fee)
				return num;
			} else {
				let tokens = ((Number(prop.tokenPrice) * (_quantity) * 1e6) / Number(SEDTprice)).toFixed(0).toString();
				let fee = ((tokens / (1e10)) * Number(prop.buySellingFee));
				// setCalToken(Number(tokens) + Number(fee))
				const num = Number(tokens) + Number(fee)
				return num;
			}
		// } else {
		// 	if (tokenType == 0) {
		// 		let tokens = ((Number(price * 1e8) * (_quantity) * 1e6) / (USDTprice)).toFixed(0).toString();
		// 		let fee = ((tokens / 1e10) * Number(prop.buySellingFee)).toFixed(0).toString();
		// 		// setCalToken(Number(tokens) + Number(fee))
		// 		const num = Number(tokens) + Number(fee)
		// 		return num;
		// 	} else if (tokenType == 1) {
		// 		let tokens = ((Number(price * 1e8) * (_quantity) * 1e6) / (USDCprice)).toFixed(0).toString();
		// 		let fee = ((tokens / (1e10)) * Number(prop.buySellingFee)).toFixed(0).toString();
		// 		// setCalToken(Number(tokens) + Number(fee))
		// 		const num = Number(tokens) + Number(fee)
		// 		return num;
		// 	} else {
		// 		let tokens = ((Number(price * 1e8) * (_quantity) * 1e6) / Number(SEDTprice)).toFixed(0).toString();
		// 		let fee = ((tokens / (1e10)) * Number(prop.buySellingFee));
		// 		// setCalToken(Number(tokens) + Number(fee))
		// 		const num = Number(tokens) + Number(fee)
		// 		return num;
		// 	}
		// }
	}
	const checkNumbers = useMemo(() => {
		return CalculateValue(quantity)
	}, [quantity])



	const connectorImages = {
		MetaMask: meta2,
		WalletConnect: walet,
	};

	return (
		<>
			{isConnected == true ? (
				<>
					{loading ? (
						<div className="text-center">
							<Button
								className="btn w-100" style={{ backgroundColor: "white" }}
							>
								<Loader />
							</Button>
						</div>
					) : (
						<form>
							<div className="sell-blance">
								<label className="form-label text-primary">Pay with</label>
								<div className="form-label blance"><span>Type of Currnecy </span>
								</div>
								<div className="input-group">
									<select
										className="form-control"
										onChange={(e) => settokenType(e.target.value)}
										required
									>
										<option value="">Select</option>
										<option value="0">USDT</option>
										<option value="1">USDC</option>
									</select>
									<span className="input-group-text">Payable Token Types</span>
								</div>
							</div>
							{/* {prop.isStartSelling === true ?
								(
									<> */}
										<div className="sell-blance">
											<label className="form-label text-primary">
												Limit Price Per Token
											</label>
											<div className="input-group">
												<input
													className="form-control"
													value={Number(Number(prop.tokenPrice) / 1e8).toFixed(2)}
												/>
												<span className="input-group-text">USD</span>
											</div>
										</div>
									{/* </>
								) : (
									<>
										{prop.isReStartSelling == true ? (
											<div className="sell-blance">
												<label className="form-label text-primary">
													Limit Price Per Token
												</label>
												<div className="input-group">
													<input
														className="form-control"
														onChange={(e) => setprice(e.target.value)}
														placeholder="0.00"
													/>
													<span className="input-group-text">USD</span>
												</div>
											</div>
										) : ("")}
									</>
								)} */}
							<div className="sell-blance">
								<label className="form-label text-primary">Property Token Quantity</label>
								<div className="input-group">
									<input
										type="text"
										className="form-control"
										onChange={(e) => setquantity(e.target.value)}
										placeholder="0.00"
									/>
									{tokenType == 0 ? (
										<span className="input-group-text">
											<span className="text-warning">
												{Number(checkNumbers / 1e6).toFixed(3)}</span> {""}-USDT</span>
									) : (
										<span className="input-group-text">
											<span className="text-warning">
												{Number(checkNumbers / 1e6).toFixed(3)}</span> {""}-USDC</span>
									)}
								</div>
							</div>
							{user._id ?
								<>
									{prop.isStartSelling == true || prop.isReStartSelling == true ? (
										<>
											{tokenType == 0 ? (
												<>
													<div className="text-center">
														<Button
															className="btn btn-primary w-75"
															onClick={() => BuybyUsdt()}
														>
															Buy

														</Button>
													</div>
												</>
											) :
												<div className="text-center">
													<Button
														className="btn btn-primary w-75"
														onClick={() => BuybyUsdc()}
													>
														Buy

													</Button>
												</div>
											}
										</>
									) : (
										<>
											<div className="text-center">
												<Link className="btn btn-primary w-75">Selling is Closed</Link>
											</div>
										</>
									)}
								</>
								:
								<>
									<div className="text-center">
										<Link to={"/login"} className="btn btn-primary w-75">login</Link>
									</div>
								</>}
						</form>
					)}

				</>
			) : (
				<>
					<div className="row">
						<div className="display-1  text-center fw-bold">
							<h5>
								To access the Dashboard
								Please connect a wallet
							</h5>
							<div>
								<button className="btn btn-primary" onClick={() => setContactModal(true)}>Connect Wallet

								</button>
							</div>
						</div>
					</div>
					<Modal className="modal fade" id="exampleModal" centered show={contactModal} onHide={setContactModal}>
						<div className="modal-content">
							<div className="modal-header">
								<h2 className="modal-title" id="exampleModalLabel">Connect a Wallet</h2>
								<button type="button" className="btn-close" onClick={() => setContactModal(false)}></button>
							</div>
							<div className=" text-center mt-5 fw-bold">
								<div className="flex flex-row cursor-pointer flex-wrap sm:mt-10 ">
									<div className="row">
										{connectors.map((connector, index) => (
											<div className="tagcloud mt-1 col" key={connector.id}>
												<Button
													type="button"
													style={{
														backgroundColor: "white",
														borderRadius: "16px",
														color: "#3c0987",
													}}
													onClick={() => connect({ connector })}
													className=" font-medium rounded-lg text-sm px-3 py-2.5 text-center inline-flex items-center ">
													<img
														loading="lazy"
														width="35"
														height="30"
														src={connectorImages[connector.name]}
														alt="meta"
														marginLeft="30"
														marginRight="20"
													/>
													<h5 className="mr-3 ml-2 mt-2"
														style={{ marginRight: "3px", marginLeft: "13px" }}
														key={connector.uid}
													>

														{connector.name}
													</h5>
												</Button>
											</div>
										))}
									</div>
								</div>
							</div>
							<div className="modal-body">
								<label className="form-label d-block fw-bold">Select your favourite wallet to Access Dashbaord.</label>
							</div>
							<div className="modal-footer">
								<a>By connecting a wallet, you agree to Uniswap Labs' Terms of Service and consent to its Privacy Policy. </a>
								<button type="button" className="btn btn-secondary" onClick={() => setContactModal(false)}>Close</button>
							</div>
						</div>
					</Modal>
				</>
			)}

		</>
	)
}
export default OrderFormss;