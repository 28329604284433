import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, Modal} from 'react-bootstrap';
import { useSelector, useDispatch } from "react-redux";
import { useAppSelector } from '../../reducer/store';
import { userDelete, usersFetch, FetchIndividual } from "../../slices/UsersSlice";
import prof from '../../images/profile/prof.jpg';
import alrt from '../../images/alrt.png';
import { WhiteList_ADDRESS } from './contract/Addresses';
import { readContract, writeContract, getBalance } from '@wagmi/core';
import { useAccount, useConnect, useDisconnect } from "wagmi";
import { config } from "../../slices/config";
import WhiteList_ABI from './contract/WhiteList.json';
import Navi from "../layouts/nav";
const Users = () => {
    const [contactModal, setContactModal] = useState();
    const [IsListed, setIsListed] = useState();
    const dispatch = useDispatch();
    const { list } = useSelector((state) => state.users);
    const { accounts, whiteListContract } = useAppSelector((state) => state.web3Connect);
    const { address, isConnected } = useAccount();

    useEffect(() => {
        dispatch(usersFetch());
    }, [dispatch]);

    const handleDelete = (id) => {
        dispatch(userDelete(id));
    };

    //_______________Start Selling____________//
    const WhiteListAddress = async (_address) => {
        await readContract(config, {
            abi: WhiteList_ABI,
            address: WhiteList_ADDRESS,
            functionName: 'isWhitelisted',
            args: [_address],
        })
            .then(async (check) => {
                setIsListed(check);
                if (check === false) {
                    await writeContract(config, {
                        abi: WhiteList_ABI,
                        address: WhiteList_ADDRESS,
                        functionName: 'addAddressToWhitelist',
                        args: [_address],
                        account: address,
                    })
                } else {
                    setContactModal(true)
                }
            })
    }

    // const WhiteListAddress = async (_address) => {
    //     try {
    //         const check = await whiteListContract?.methods.
    //         isWhitelisted(_address).call();
    //         setIsListed(check);
    //         if (check === false) {
    //             let add = await whiteListContract?.methods.
    //             addAddressToWhitelist(_address).send({ from: accounts[0] });
    //             console.log('white Listed', add);
    //         } else {
    //             setContactModal(true)
    //         }

    //     } catch (error) {
    //         console.log("white Listed error", error)
    //     }
    // }

    return (
        <>
            <Navi/>
            <div className="content-body" style={{ minHeight: window.screen.height - 45 }}>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-body py-2">
                                    <div className="row d-flex align-items-center justify-content-between">
                                        <div className="col-xl-3 col-md-6 col-sm-6">
                                            <div className="input-group custom-search-area my-2">
                                                <span className="input-group-text">
                                                    <Link to={"#"}>
                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M20.5605 18.4395L16.7527 14.6317C17.5395 13.446 18 12.0262 18 10.5C18 6.3645 14.6355 3 10.5 3C6.3645 3 3 6.3645 3 10.5C3 14.6355 6.3645 18 10.5 18C12.0262 18 13.446 17.5395 14.6317 16.7527L18.4395 20.5605C19.0245 21.1462 19.9755 21.1462 20.5605 20.5605C21.1462 19.9747 21.1462 19.0252 20.5605 18.4395V18.4395ZM5.25 10.5C5.25 7.605 7.605 5.25 10.5 5.25C13.395 5.25 15.75 7.605 15.75 10.5C15.75 13.395 13.395 15.75 10.5 15.75C7.605 15.75 5.25 13.395 5.25 10.5V10.5Z" fill="var(--primary)"></path>
                                                        </svg>
                                                    </Link>
                                                </span>
                                                <input type="text" className="form-control border-start-0" placeholder="Search Contact" />
                                            </div>
                                        </div>
                                        <div className="col-xl-9 col-md-6 col-sm-12 my-2">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12">
                            <div className="row">
                                {list.map((data, index) => (
                                    <div className="col-xl-3 col-md-6" key={index}>
                                        <div className="card contact_list ">
                                            <div className="card-body text-center">
                                                <div className="user-content">
                                                    <div className="user-info">
                                                        <div className="user-img">
                                                            <img src={prof} alt="" />
                                                        </div>
                                                        <div className="user-details">
                                                            {data.isAdmin ? (
                                                                <h4 className="number">Admin</h4>
                                                            ) : (
                                                                <h4 className="number">Customer</h4>
                                                            )}
                                                            <h4 className="user-name">{data.name}</h4>
                                                            <span className="mail">{data.phone}</span>
                                                            <span className="mail fs-8" >{data.email}</span>
                                                            <span className="mail fs-8" >{data.walletAddress?.substring(0, 12) + "...."}</span>

                                                        </div>
                                                    </div>
                                                    <Dropdown>
                                                        <Dropdown.Toggle as="div" className="i-false btn-link btn sharp tp-btn btn-primary pill">
                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M12.0012 9.35986C11.6543 9.35986 11.3109 9.42818 10.9904 9.56091C10.67 9.69365 10.3788 9.88819 10.1335 10.1335C9.88829 10.3787 9.69374 10.6699 9.56101 10.9903C9.42828 11.3108 9.35996 11.6542 9.35996 12.0011C9.35996 12.3479 9.42828 12.6914 9.56101 13.0118C9.69374 13.3323 9.88829 13.6234 10.1335 13.8687C10.3788 14.1139 10.67 14.3085 10.9904 14.4412C11.3109 14.5739 11.6543 14.6423 12.0012 14.6423C12.7017 14.6421 13.3734 14.3637 13.8686 13.8682C14.3638 13.3728 14.6419 12.701 14.6418 12.0005C14.6416 11.3 14.3632 10.6282 13.8677 10.133C13.3723 9.63782 12.7004 9.3597 12 9.35986H12.0012ZM3.60116 9.35986C3.25431 9.35986 2.91086 9.42818 2.59042 9.56091C2.26997 9.69365 1.97881 9.88819 1.73355 10.1335C1.48829 10.3787 1.29374 10.6699 1.16101 10.9903C1.02828 11.3108 0.959961 11.6542 0.959961 12.0011C0.959961 12.3479 1.02828 12.6914 1.16101 13.0118C1.29374 13.3323 1.48829 13.6234 1.73355 13.8687C1.97881 14.1139 2.26997 14.3085 2.59042 14.4412C2.91086 14.5739 3.25431 14.6423 3.60116 14.6423C4.30165 14.6421 4.97339 14.3637 5.4686 13.8682C5.9638 13.3728 6.24192 12.701 6.24176 12.0005C6.2416 11.3 5.96318 10.6282 5.46775 10.133C4.97231 9.63782 4.30045 9.3597 3.59996 9.35986H3.60116ZM20.4012 9.35986C20.0543 9.35986 19.7109 9.42818 19.3904 9.56091C19.07 9.69365 18.7788 9.88819 18.5336 10.1335C18.2883 10.3787 18.0937 10.6699 17.961 10.9903C17.8283 11.3108 17.76 11.6542 17.76 12.0011C17.76 12.3479 17.8283 12.6914 17.961 13.0118C18.0937 13.3323 18.2883 13.6234 18.5336 13.8687C18.7788 14.1139 19.07 14.3085 19.3904 14.4412C19.7109 14.5739 20.0543 14.6423 20.4012 14.6423C21.1017 14.6421 21.7734 14.3637 22.2686 13.8682C22.7638 13.3728 23.0419 12.701 23.0418 12.0005C23.0416 11.3 22.7632 10.6282 22.2677 10.133C21.7723 9.63782 21.1005 9.3597 20.4 9.35986H20.4012Z" fill="#A098AE" />
                                                            </svg>
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu className="dropdown-menu dropdown-menu-end" align="end">
                                                            <Dropdown.Item onClick={() => handleDelete(data._id)}>Delete</Dropdown.Item>
                                                            {/* <Dropdown.Item>Edit</Dropdown.Item> */}
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </div>
                                                <div className="contact-icon">
                                                    <div className="">
                                                        <button type="button" className="btn btn-outline-primary" data-mdb-ripple-init data-mdb-ripple-color="dark"
                                                            onClick={() => WhiteListAddress(data.walletAddress)}
                                                        >
                                                            Add to WhiteList
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}


                            </div>
                        </div>
                    </div>
                    <Modal className="modal fade" id="exampleModal" centered show={contactModal} onHide={setContactModal}>
                        <div className="modal-content">
                            <div className="mt-5">
                                <h5 className="modal-title " id="exampleModalLabel"></h5>
                            </div>
                            <div className="text-center">
                                <div className="user-img">
                                    <img width="50" height="50" src={alrt} alt="" />
                                </div>
                                <label className="form-label d-block mb-4 mt-4">Your Address aleardy White-Listed</label>
                            </div>
                            <div className="mb-3 text-center">
                                <button type="button" className="btn btn-outline-primary" data-mdb-ripple-init data-mdb-ripple-color="dark"
                                    onClick={() => setContactModal(false)}>Close</button>
                            </div>
                        </div>
                    </Modal>
                </div>
            </div>
        </>
    )
}
export default Users;