import React, { useContext } from "react";

/// React router dom
import { Routes, Route, Outlet } from "react-router-dom";
/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

import ScrollToTop from "./layouts/ScrollToTop";
/// Dashboard
import Home from "./components/Dashboard/Home";


/// App
import AppProfile from "./components/AppsMenu/AppProfile/AppProfile";
import Compose from "./components/AppsMenu/Email/Compose/Compose";
import Inbox from "./components/AppsMenu/Email/Inbox/Inbox";
import Read from "./components/AppsMenu/Email/Read/Read";
import Calendar from "./components/AppsMenu/Calendar/Calendar";


/// Plugins
import Select2 from "./components/PluginsMenu/Select2/Select2";
import MainNouiSlider from "./components/PluginsMenu/NouiSlider/MainNouiSlider";
import MainSweetAlert from "./components/PluginsMenu/SweetAlert/SweetAlert";
import Toastr from "./components/PluginsMenu/Toastr/Toastr";
import JqvMap from "./components/PluginsMenu/JqvMap/JqvMap";
import Lightgallery from "./components/PluginsMenu/Lightgallery/Lightgallery";


// Widget
import Widget from "./pages/Widget";
import Owner from "./components/Owner";



/// Form
import PropertyList from "./components/PropertyList";
import PropertyDetails from "./components/PropertyDetails";
import PropertyCreate from "./components/PropertyCreate";
import BlogCreate from "./components/BlogCreate";
import ListedPropRequest from "./components/ListedPropRequest";
import BlogList from "./components/BlogList";

/// Users
import Users from "./components/Users";

/// Pages
import LockScreen from "./pages/LockScreen";
import Error400 from "./pages/Error400";
import Error403 from "./pages/Error403";
import Error404 from "./pages/Error404";
import Error500 from "./pages/Error500";
import Error503 from "./pages/Error503";
import { ThemeContext } from "../context/ThemeContext";
import Marketplace from "./pages/Marketplace";
import EnterEmail from "./layouts/EnterEmail";
import OtpCode from "./layouts/OtpCode";

const Markup = () => {
  const allroutes = [
    { url: "dashboard", component: <Home /> },
    { url: "", component: <Marketplace/> },
    { url: "propertydetails", component: <PropertyDetails/> },
    { url: "otpcode", component: <OtpCode/> },
    { url: "enteremail", component: <EnterEmail/> },
    /// Apps
    { url: "app-profile", component: <AppProfile /> },
    { url: "email-compose", component: <Compose /> },
    { url: "email-inbox", component: <Inbox /> },
    { url: "email-read", component: <Read /> },
    { url: "app-calender", component: <Calendar /> },
  

    ///// Plugin
    { url: "uc-select2", component: <Select2 /> },
    { url: "uc-noui-slider", component: <MainNouiSlider /> },
    { url: "uc-sweetalert", component: <MainSweetAlert /> },
    { url: "uc-toastr", component: <Toastr /> },
    { url: "map-jqvmap", component: <JqvMap /> },
    { url: "uc-lightgallery", component: <Lightgallery /> },
    //
 
    ///// Widget
    { url: "widget-basic", component: <Widget /> },
    { url: "/dashboard/Profile/:id", component: <Owner /> },

    
    ///// Form
    { url: "/dashboard/propertylist", component: <PropertyList /> },
    {url: "propertydetails/:id", component: <PropertyDetails />},
    { url: "/dashboard/propertyCreate", component: <PropertyCreate /> },
    { url: "/dashboard/blogCreate", component: <BlogCreate /> },
    { url: "/dashboard/listedPropRequest", component: <ListedPropRequest /> },
    { url: "/dashboard/bloglist", component: <BlogList /> },

    ///// Users
    { url: "/dashboard/users", component: <Users /> },

  ];

  
  return (
    <>
        <Routes>
          <Route path='page-lock-screen' element={<LockScreen />} />
          <Route path='page-error-400' element={<Error400 />} />
          <Route path='page-error-403' element={<Error403 />} />
          <Route path='page-error-404' element={<Error404 />} />
          <Route path='page-error-500' element={<Error500 />} />
          <Route path='page-error-503' element={<Error503 />} />
          <Route element={<MainLayout />} >
            {allroutes.map((data, i) => (
              <Route key={i} exact path={`${data.url}`} element={data.component}
              />
            ))}
          </Route>
        </Routes>
      <ScrollToTop />

    </>
  );
};


function MainLayout() {
  const { menuToggle } = useContext(ThemeContext);
  return (
    <div id="main-wrapper" className={`show ${menuToggle ? "menu-toggle" : ""}`}>
          <Outlet />
    </div>
  )

};

export default Markup;

