import React, { Fragment, useEffect, useState } from "react";
import { useAppSelector, useAppDispatch } from '../../reducer/store';
import { ADDRESSES } from "../../slices/adminEventSlice";
import Mnavbar from "../layouts/Mnavbar";
import MarketPage from "../components/MarketPage";
import Footr from "../layouts/Footr";
import centerellipse from "../../images/centerellipse.png";
import videop from "../../images/hero-wave-background.mov";


const Marketplace = () => {
  const [adminEvent, setadminEvent] = useState();
  const dispatch = useAppDispatch();
  const { socketContract } = useAppSelector((state) => state.web3Connect);


  const listenAdminEvent = () => {
    socketContract.events.PropertyAdmin({}, function (error, event) {
      setadminEvent(event.returnValues[0])
      dispatch(ADDRESSES(event.returnValues[0]))
    })
  }
  useEffect(() => {
    if (socketContract) {
      async function EventAdminProp() {
        !adminEvent && await listenAdminEvent();
      }
      EventAdminProp();

    }
  }, [socketContract, adminEvent])



  return (
    <>
      {/* // <div className="parent-video">
    //   <div className="hero-section-background-video-container">
    //     <video className="background-video" autoPlay loop muted playsInline>
    //       <source src={videop} type="video/mp4" />
    //       Your browser does not support the video tag.
    //     </video>
    //     <div className="ellipse-class">
    //       <img src={centerellipse} alt="" />
    //     </div> */}
      <Mnavbar />
      <div className="dz-bnr-inr">
        <div className="body-content" style={{ minHeight: window.screen.height - 45 }}>
          <div className="container-fluid">
            <Fragment>
              <MarketPage />
            </Fragment>
          </div>
        </div>
      </div>
      <Footr />
      {/* </div>
    </div> */}
    </>

  );
};

export default Marketplace;

