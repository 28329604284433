import React, { useContext, useEffect, useReducer, useState, useRef } from 'react';
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { url, setHeaders } from "../../../slices/api";
import { Nav, Tab } from 'react-bootstrap';
import Navi from "../../layouts/nav";
import Marquee from "react-fast-marquee";
import { loadBlockchain, updatAccount } from '../../../slices/web3ContractSlice';
import { useAppDispatch, useAppSelector } from '../../../reducer/store';
import Contract_ABI from "../contract/SecondaryDAO.json"
import Property_ABI from '../contract/Property.json';
import Escrow_ABI from '../contract/Escrow.json'
import { Button } from "react-bootstrap";
import { Modal } from 'react-bootstrap';
import { BtcIcon, XtzIcon, EthIcon, } from './SvgIcon';
import walet from '../../../images/walet.png';
import meta2 from '../../../images/meta2.png';
import usdt from '../../../images/usdt.png';
import logoo from '../../../images/logo/logoo.png';
import logoss from '../../../images/logo/logoss.png';
import logos from '../../../images/logo/logos.png';
import { readContract } from '@wagmi/core';
import { useAccount, useConnect, useDisconnect } from "wagmi";
import { config } from "../../../slices/config";
import Kyc from './Kyc';
import { toast } from "react-toastify";
import QRCode from "react-qr-code";
import FilteringTable from '../FilteringTable/FilteringTable';
const CONTRACT_ADDRESS = "0x910603b35A1fD922094F5243BC30182611db13bC"
const USDCaddr = "0x0153002d20B96532C639313c2d54c3dA09109309";
const USDTaddr = "0x80EDee6f667eCc9f63a0a6f55578F870651f06A4";
const aggregatorV3InterfaceABI = [
	{
		inputs: [],
		name: "decimals",
		outputs: [{ internalType: "uint8", name: "", type: "uint8" }],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [],
		name: "description",
		outputs: [{ internalType: "string", name: "", type: "string" }],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [{ internalType: "uint80", name: "_roundId", type: "uint80" }],
		name: "getRoundData",
		outputs: [
			{ internalType: "uint80", name: "roundId", type: "uint80" },
			{ internalType: "int256", name: "answer", type: "int256" },
			{ internalType: "uint256", name: "startedAt", type: "uint256" },
			{ internalType: "uint256", name: "updatedAt", type: "uint256" },
			{ internalType: "uint80", name: "answeredInRound", type: "uint80" },
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [],
		name: "latestRoundData",
		outputs: [
			{ internalType: "uint80", name: "roundId", type: "uint80" },
			{ internalType: "int256", name: "answer", type: "int256" },
			{ internalType: "uint256", name: "startedAt", type: "uint256" },
			{ internalType: "uint256", name: "updatedAt", type: "uint256" },
			{ internalType: "uint80", name: "answeredInRound", type: "uint80" },
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [],
		name: "version",
		outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
		stateMutability: "view",
		type: "function",
	},
]

const Home = () => {
	const params = useParams();
	const dispatch = useAppDispatch()
	const [transaction, settransaction] = useState([]);
	const [users, setUsers] = useState([]);
	const [usersPerc, setUsersPerc] = useState(0);
	const [totalNoOfProp, settotalNoOfProp] = useState(0);
	const [totalBalance, settotalBalance] = useState(0);
	const [USDTprice, setUSDTprice] = useState(0);
	const [USDCprice, setUSDCprice] = useState(0);
	const [EscrowAdd, setEscrowAdd] = useState([]);
	const [popular, setPopular] = useState([]);
	const [filtered, setFiltered] = useState([]);
	const [activeGenre, setActiveGenre] = useState(0);
	const [contactModal, setContactModal] = useState(false);
	// const [isConnected, setIsConnected] = useState(false);
	const componentRef = useRef();
	const sort = 6;
	const activePag = useRef(0);
	const [test, settest] = useState(0);
	const [datass, setData] = useState(
		document.querySelectorAll("#status_wrapper tbody tr")
	);
	const { items: data } = useAppSelector((state) => state.products);
	const { Indata: datas } = useAppSelector((state) => state.rent);
	const user = useAppSelector((state) => state.auth);
	const { address, isConnected } = useAccount();
	const { connectors, connect, status, error } = useConnect()
	const { disconnect } = useDisconnect();
	const [isKYCDataLoading, setIsKYCDataLoading] = useState(true);
	const [activeWalletData, setActiveWalletData] = useState([]);
	const [userKycData, setUserKycData] = useState(null);
	const [userData, setUserData] = useState(null);
	const [userKycVerificationStatus, setUserKycVerificationStatus] = useState(null);

	// const validMethodIDs = [
	// 	"0xa020a602", // CreateProperty
	// 	"0x715018a6", // renounceOwnership
	// 	"0xd547741f",  // revokeRole
	// 	"0xf2fde38b",  // transferOwnership
	// 	"0x28af29fe",  // revokePropertyAdminRole
	// 	"0x8da5cb5b",  // owner
	// 	"0x6b9c9bc7",  //GrantPropOwnerRole
	// ];
	// useEffect(() => {
	// 	const fetchTransaction = async () => {
	// 		let owner = await contract?.methods.owner().call();
	// 		let AdminAdd = await contract?.methods.PropertyAdminAddress().call();
	// 		console.log("owner", owner);
	// 		console.log("AdminAdd", AdminAdd);
	// 	}; fetchTransaction()
	// });

	// useEffect(() => {
	// 	if (window.ethereum) {
	// 		const fetchTransaction = async () => {
	// 			try {
	// 				fetch(`https://api-sepolia.arbiscan.io/api?module=account&action=txlist&address=0x7BB0c04682Bc957827fdF14a7Cc07A9C350C7C08&sort=desc&apikey=XX4T9WY8IRNXHSUC5ZN6FJ1DTSMT9NXPPC`)
	// 					.then(response => {
	// 						return response.json()
	// 					})
	// 					.then(data => {
	// 						const transactions = data.result.filter((transaction) => validMethodIDs.includes(transaction.methodId));
	// 						settransaction(transactions)
	// 					})
	// 			} catch (err) {
	// 				console.log(err);
	// 			}
	// 		};
	// 		fetchTransaction();
	// 	}
	// }, []);


	// const handleDisconnect = async () => {
	// 	if (isConnected && address) {
	// 	  console.log(`Disconnecting wallet with address: ${address}`);

	// 	  // Call the backend to mark the wallet as inactive
	// 	  try {
	// 		const response = await axios.patch(
	// 		  `${url}/users/wallet/disconnect/${user._id}`,
	// 		  {
	// 			walletAddress: address, // Send the address to mark inactive
	// 		  }
	// 		);
	// 		console.log(response.data.message);
	// 	  } catch (error) {
	// 		console.error("Error disconnecting wallet:", error);
	// 	  }
	// 	}
	// 	disconnect(); // Call disconnect function from WAGMI
	//   };

	// useEffect(() => {
	// 	async function fetchData() {
	// 		try {
	// 			const res = await axios.get(`${url}/users/stats`, setHeaders());

	// 			res.data.sort(compare);
	// 			setUsers(res.data);
	// 			setUsersPerc(
	// 				((res.data[0].total - res.data[1].total) / res.data[1].total) * 100
	// 			);
	// 		} catch (err) {
	// 			console.log(err);
	// 		}
	// 	}
	// 	fetchData();
	// }, []);


	// const EscrowBalance = async (addres) => {
	// 	const result = await readContract(config, {
	// 		abi: Escrow_ABI,
	// 		address: addres,
	// 		functionName: 'balanceOf',
	// 		args: [addres],
	// 	})
	// 	return result;
	// }

	// const Cal_Property = async () => {
	// 	const result = await readContract(config, {
	// 		abi: Contract_ABI,
	// 		address: CONTRACT_ADDRESS,
	// 		functionName: 'NoProperties',
	// 	})
	// 	return result;
	// }

	// useEffect(() => {
	// 	const TotalProperties = async () => {
	// 		await Cal_Property()
	// 			.then((result) => {
	// 				settotalNoOfProp(Number(result));
	// 			})
	// 	}
	// 	TotalProperties();
	// })


	// useEffect(() => {
	// 	const Balance = async () => {
	// 		for (let i = 0; i < data.length; i++) {
	// 			try {
	// 				const Addrs = [];
	// 				await EscrowAcont(data[i].uid)
	// 					.then(async (result) => {
	// 						Addrs.push(result);
	// 						if (Addrs.length > 0) {
	// 							const bal = [];
	// 							for (let i = 0; i < Addrs.length; i++) {
	// 								await EscrowBalance(Addrs[i])
	// 									.then((result) => {
	// 										bal.push(Number((result)) / 1e18).toFixed(3);
	// 										let balance = 0;
	// 										for (let i = 0; i < bal.length; i++) {
	// 											balance += bal[i]
	// 										}
	// 										settotalBalance(balance);
	// 									})
	// 							}
	// 						}
	// 					})
	// 				setEscrowAdd(Addrs);
	// 			} catch (error) {
	// 				console.log("Set Escrow Balance Error", error)
	// 			}
	// 		}

	// 	}
	// 	Balance();
	// })

	// useEffect(() => {
	// 	const USDTPrice = async () => {
	// 		try {
	// 			await axios.get(`${url}/products/usdt/${params.id}`)
	// 				.then((result) => {
	// 					if (USDTprice == 0) {
	// 						// const price = Number((result.data)).toFixed(3);
	// 						setUSDTprice(result);
	// 					}
	// 				})
	// 		} 
	// 		catch (err) {
	// 			console.log(err);
	// 		}
	// 	};
	// 	USDTPrice();
	// })

	// useEffect(() => {
	// 	const USDCPrice = async () => {
	// 		try {
	// 			await axios.get(`${url}/products/usdc/${params.id}`)
	// 				.then((roundData) => {
	// 					if (USDCprice == 0) {
	// 						const price = Number((roundData.data) / 1e8).toFixed(3);
	// 						setUSDCprice(price);
	// 					}
	// 				})
	// 		} catch (err) {
	// 			console.log(err);
	// 		}
	// 	}
	// 	USDCPrice();
	// })
	// useEffect(() => {
	// 	if (window.ethereum) {
	// 		try {
	// 			const datas = [];
	// 			// if (web3) {
	// 			// 	const Balance = async () => {
	// 			// 		for (let i = 0; i < data.length; i++) {
	// 			// 			try {
	// 			// 				const Addrs = [];
	// 			// 				datas.push(new web3.eth.Contract(Property_ABI, data[i].uid));
	// 			// 				for (let i = 0; i < datas.length; i++) {
	// 			// 					Addrs.push(await datas[i]?.methods.EscrowAccount().call());
	// 			// 					if (Addrs.length > 0) {
	// 			// 						const EscAddrs = [];
	// 			// 						for (let i = 0; i < Addrs.length; i++) {
	// 			// 							EscAddrs.push(new web3.eth.Contract(Escrow_ABI, Addrs[i]));
	// 			// 							if (EscAddrs.length > 0) {
	// 			// 								const bal = [];
	// 			// 								for (let i = 0; i < EscAddrs.length; i++) {
	// 			// 									bal.push(Number((await EscAddrs[i]?.methods.balanceOf(Addrs[i]).call())) / 1e18).toFixed(3);
	// 			// 									let balance = 0;
	// 			// 									for (let i = 0; i < bal.length; i++) {
	// 			// 										balance += bal[i]
	// 			// 									}
	// 			// 									settotalBalance(balance);
	// 			// 								}
	// 			// 							}
	// 			// 						}
	// 			// 					}
	// 			// 				}
	// 			// 				setEscrowAdd(Addrs);
	// 			// 			} catch (error) {
	// 			// 				console.log("data fecthing Error", error)
	// 			// 			}
	// 			// 		}

	// 			// 	}
	// 			// 	Balance();
	// 			// }
	// 			// if (web3) {
	// 			// 	const TotalProperty = async () => {
	// 			// 		let TotalNoOfProp = await contract.methods.NoProperties().call();
	// 			// 		settotalNoOfProp(TotalNoOfProp - 1);
	// 			// 	}
	// 			// 	TotalProperty();
	// 			// }
	// 			// if (web3) {
	// 			// 	const USDTPrice = async () => {
	// 			// 		const USDTpriceFeed = new web3.eth.Contract(aggregatorV3InterfaceABI, USDTaddr);
	// 			// 		USDTpriceFeed.methods.latestRoundData().call()
	// 			// 			.then((roundData) => {
	// 			// 				const price = Number((roundData.answer) / 1e8).toFixed(3);
	// 			// 				setUSDTprice(price);
	// 			// 			})
	// 			// 	}
	// 			// 	USDTPrice();
	// 			// }
	// 			// if (web3) {
	// 			// 	const USDCPrice = async () => {
	// 			// 		const USDCpriceFeed = new web3.eth.Contract(aggregatorV3InterfaceABI, USDCaddr);
	// 			// 		USDCpriceFeed.methods.latestRoundData().call()
	// 			// 			.then((roundData) => {
	// 			// 				const price = Number((roundData.answer) / 1e8).toFixed(3);
	// 			// 				setUSDCprice(price);
	// 			// 			})
	// 			// 	}
	// 			// 	USDCPrice();
	// 			// }
	// 		} catch (error) {
	// 			console.log("Balance Count Error", error)
	// 		}
	// 	}
	// }, [])

	const headers = {
		"Content-Type": "application/json",
		Authorization: "Token e31169640d9147493929ab77c9128470b16d",
	};

	// useEffect(() => {
	// 	if (user._id) {
	// 		const fetchUserData = async () => {
	// 			try {
	// 				setIsKYCDataLoading(true);
	// 				const currentUserResponse = await axios.get(
	// 					`${url}/users/find/${user._id}`,
	// 					{
	// 						headers: headers,
	// 					}
	// 				);
	// 				const { data } = currentUserResponse;
	// 				setUserData(data);
	// 				const updateWallet = data.wallets.filter(
	// 					(wallet) => wallet.active === true
	// 				);
	// 				if (updateWallet.length > 0 && address) {
	// 					const doubleCheckWithAccountHook =
	// 						updateWallet[0].address === address ? updateWallet : [];
	// 					setActiveWalletData(doubleCheckWithAccountHook);
	// 					setIsKYCDataLoading(false);
	// 				}
	// 			} catch (userError) {
	// 				toast.error("Error fetching user data");
	// 			}
	// 		};
	// 		fetchUserData();
	// 	}
	// }, [user._id]);


	useEffect(() => {
		if (user._id) {
		  const fetchUserData = async () => {
			try {
			  setIsKYCDataLoading(true);
			  const currentUserResponse = await axios.get(
				`${url}/users/find/${user._id}`,
				{
				  headers: headers,
				}
			  );
			  const { data } = currentUserResponse;
			  setUserData(data);	
			  // Fetch KYC Data Logic
			  if (data._id && data.verification_id) {
				try {
				  const verificationRes = await axios.get(
					`https://kyc-api.amlbot.com/verifications/${data.verification_id}`,
					{
					  headers: headers,
					}
				  );
	
				  if (
					data._id &&
					data.applicant_id &&
					verificationRes.data.status !== "pending"
				  ) {
					try {
					  const kycRes = await axios.get(
						`${url}/kyc/find/${data._id}`,
						{
						  headers: headers,
						}
					  );
					  setUserKycVerificationStatus(null); // Skip pending status
					  setUserKycData(kycRes.data.kyc_data);
					  // console.log("kycRes.data", kycRes.data);
					  setIsKYCDataLoading(false);
					} catch (kycError) {
					  setIsKYCDataLoading(false);
					  console.error("Error fetching KYC data:", kycError);
					  toast.error("Error fetching KYC data");
					}
				  }
	
				  setUserKycVerificationStatus(verificationRes.data);
				  setIsKYCDataLoading(false);
				} catch (kycVerificationError) {
				  setIsKYCDataLoading(false);
				  console.error(
					"Error fetching KYC verification:",
					kycVerificationError
				  );
				  toast.error("Error fetching KYC verification");
				}
			  }
			  setIsKYCDataLoading(false);
			} catch (userError) {
			  setIsKYCDataLoading(false);
			  console.error("Error fetching user data:", userError);
			  toast.error("Error fetching user data");
			}
		  };
		  fetchUserData();
		}
	  }, [user._id]);

	const connectorImages = {
		MetaMask: meta2,
		WalletConnect: walet,
	};

	return (
		<>
			<Navi />
			<div className="content-body" style={{ minHeight: window.screen.height - 45 }}>
				<div className="container-fluid">
					{isConnected == true ? (
						<>
							{/* && activeWalletData[0]?.addressVerificationStatus == null */}
							{/* {activeWalletData.length > 0 && activeWalletData[0]?.addressVerificationStatus == null && activeWalletData[0]?.addressVerificationStatus?.data.status === "success" ? */}
							{userKycData?.status === "completed" && userKycData.verified ?
							// {activeWalletData.length > 0 && activeWalletData[0]?.addressVerificationStatus == null  ?

							<>
								<div className="row">
									<div className="col-xxl-12">
										<div className="overflow-hidden bg-transparent dz-crypto-scroll shadow-none">
											<div className="js-conveyor-example">
												<ul className="crypto-list" id="crypto-webticker">
													<Marquee
														speed={80}
														loop={0}
														pauseOnHover={true}
													>
														{data.map((item, index) => (

															<div className="card overflow-hidden" key={index} style={{ marginLeft: "20px" }}>
																<div className="card-body d-flex align-items-center" >
																	<div className="me-4">
																		<p className="mb-2 fs-13"><i className="fa fa-caret-up scale5 me-2 text-success" aria-hidden="true"></i>{new Date(item.createdAt).toDateString()}</p>
																		<h4 className="heading mb-0">{item.name}</h4>
																	</div>
																	<div className="coin-img">
																		<img width="42" height="42" src={item.image?.url} className="img-fluid" alt="" />
																	</div>
																</div>
															</div>
														))}
													</Marquee>
												</ul>
											</div>
										</div>
									</div>

									<div className="col-xl-6 col-lg-6 col-sm-6">
										<div className="card">
											<div className="card-header d-block border-0 pb-0 brand-logo">
												<h3>Current Rent Balance (USD)</h3>
											</div>
											<div className="card-body pt-0">
												<div className="row align-items-center">
													<div className="col-sm-9">
														<h2><strong className="d-block fs-155">$23,741.60</strong></h2>
														<span>
															Rent Earn in USDT:{" "}
															<strong className="d-block">153.300 USDT <img src={usdt} width="20px" height="20px" alt="" /> </strong>
														</span>
														<small className="text-muted">
															Current exchange rate 1 USDT = $0.983 USD
														</small>
														{/* <br />
															<Button className='mt-3'>Withdraw</Button> */}
													</div>
													<div className="col-sm-3 mt-3">
														{" "}
														<div style={{ height: "auto", margin: "0 auto", maxWidth: 64, width: "100%" }}>
															<QRCode
																size={256}
																style={{ height: "auto", maxWidth: "100%", width: "100%" }}
																value={user.walletAddress}
																viewBox={`0 0 256 256`}
															/>
														</div>
														{" "}
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="col-xl-6 col-lg-6 col-sm-6">
										<div className="card prim-card">
											<div className="card-body py-3">
												<img src={logoo} length="70px" width="70px" alt="" />

												<div className="d-flex mt-2">
													<div className="d-flex justify-content-between align-items-baseline ">
														<h2 className=" text-white">$23,741.60 {""}{""}
															<span className="fs-14 font-w400 text-white">
																Total Rent Earn
															</span>
														</h2>
													</div>
													<div className="rec-design">
														<div className="rec-design1">
														</div>
														<div className="rec-design2">
														</div>
													</div>
												</div>
												<div className="d-flex align-items-center justify-content-between mt-3">
													<div className="prim-info">
														<span>Current Account Value</span>
														<h4 className='text-white'>$23,741.60</h4>
													</div>
													<div className="">
														<div className="prim-info">
															<span>Total Property Value</span>
															<h4 className='text-white'>$23,741.60</h4>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>

									<div className="col-xl-8">
										<div className="row">
											{/* <div className="col-xl-12">
												<div className="card">
													<div className="card-body">
														<div className="previews-info-list" >
															<div className="pre-icon">
																<div className="row">
																	<div className="col">
																		<h3 style={{ color: "#3c0987" }}>Total Mint Tokens</h3>
																	</div>
																	<label className="">Property Tokens for All Propjects</label>
																</div>
															</div>
															<span className="count">
																<h2 style={{ color: "#9568FF", fontSize: "35px" }}>{totalBalance}
																	<i className="fa fa-caret-up scale5 text-success" style={{ fontSize: "10px" }} aria-hidden="true"></i>
																</h2>
															</span>
														</div>
														<div className="previews-info-list" >
															<div className="price data">
																<span>Total Properties</span>
																<h4>{totalNoOfProp} <sub>Property</sub></h4>
															</div>
															<div className="price data">
																<span>Current Price</span>
																<h4>{USDTprice} <sub>USDT</sub></h4>
															</div>
															<div className="price data">
																<span>Current Price</span>
																<h4>{USDCprice}<sub>USDC</sub></h4>
															</div>
														</div>
													</div>
												</div>
											</div> */}
											<div className="col-xl-12">
												<div className="card">
													<Tab.Container defaultActiveKey="All">
														<div className="card-header border-0 pb-2 flex-wrap">
															<h4 className="heading">Recent Activity</h4>
															<>
																<Nav className="order nav nav-tabs">
																	<Nav.Link as="button" eventKey="All" type="button">Veiw History</Nav.Link>
																</Nav>
															</>
														</div>
														<div className="card-body pt-0 pb-0">
															{/* <Tab.Content >
																	<Tab.Pane eventKey="All">
																	</Tab.Pane>
																</Tab.Content> */}
															<FilteringTable />
														</div>
													</Tab.Container>
												</div>
											</div>
										</div>
									</div>
									<div className="col-xl-4">
										<div className="row">
											<div className="col-xl-12 col-sm-6">

												<div className="card">
													<div className="card-header py-2">
														<h2 className="heading">Total Listed Properties <span>({totalNoOfProp})</span></h2>
													</div>
													<div className="card-body pt-0 pb-3 px-2">
														<Tab.Container defaultActiveKey="Openorder">
															<nav className="buy-sell style-1">
																{/* <Nav className=" nav-tabs" id="nav-tab1" role="tablist">
													<Nav.Link as="button" className="nav-link " eventKey="Openorder" type="button" >Buy</Nav.Link>
													<Nav.Link as="button" className="nav-link" eventKey="Orderhistory" type="button" >Sell</Nav.Link>
												</Nav> */}
															</nav>
															<Tab.Content>
																<Tab.Pane eventKey="Openorder" >
																	<div className="list-table danger mt-4">
																		{data.map((item, index) => (
																			<div className="card list-table  overflow-hidden " key={index}>
																				<div className="card-body  previews-info-list">
																					<span>
																						<p className="mb-2 fs-13 "><i className="fa fa-caret-up scale5 me-2 text-success" aria-hidden="true"></i>
																							{item.uid?.substring(0, 21) + "...."}
																						</p>
																						<span className='text-success ml-5'>{""} {item.name}</span>
																						<h4 className="fs-13 mt-2">Created:_ {""}<span className='text-primary'>
																							{new Date(item.createdAt).toDateString()}</span></h4>
																					</span>
																					<span className="text-end">
																						{/* <p className="mb-1" >Expire: 34h</p> */}
																						<Link to={"#"} className="ico-icon">
																							<img className="rounded" width="50" height="50" src={item.image?.url} alt="" />
																						</Link>
																					</span>
																				</div>
																			</div>
																		))}
																	</div>
																</Tab.Pane>
																<Tab.Pane eventKey="Orderhistory" >
																	<div className="list-table danger mt-4">
																		{data.map((item, index) => (
																			<div className="card list-table  overflow-hidden " key={index}>
																				<div className="card-body  previews-info-list">
																					<span>
																						<p className="mb-2 fs-13"><i className="fa fa-caret-up scale5 me-2 text-success" aria-hidden="true"></i>
																							Accepet Crypto <span className='text-success'> USDT</span>
																						</p>
																						<h4 className="heading mb-0">49.00<span>(USD) </span></h4>
																						<h4 className="fs-13">Property Token:_{""} <span className='text-primary'>{""}4.00 SEDT</span></h4>
																					</span>
																					<span className="text-end">
																						<p className="mb-1" >Expire: 34h</p>
																						<button className="btn btn-danger text-end" >Sell
																						</button>
																					</span>
																				</div>
																			</div>
																		))}
																	</div>
																</Tab.Pane>
															</Tab.Content>
														</Tab.Container>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</>
							:
								<>
									<Kyc />
								</>
							 }
						</>

					) : (
						<>
							<div className="row">
								<div className="display-1  text-center fw-bold">
									<h5>
										To access the Dashboard
										Please connect a wallet
									</h5>
									<div>
										<button className="btn btn-primary" onClick={() => setContactModal(true)}>Connect Wallet

										</button>
									</div>
								</div>
							</div>
							<Modal className="modal fade" id="exampleModal" centered show={contactModal} onHide={setContactModal}>
								<div className="modal-content">
									<div className="modal-header">
										<h2 className="modal-title" id="exampleModalLabel">Connect a Wallet</h2>
										<button type="button" className="btn-close" onClick={() => setContactModal(false)}></button>
									</div>
									<div className=" text-center mt-5 fw-bold">
										<div className="flex flex-row cursor-pointer flex-wrap sm:mt-10 ">
											<div className="row">
												{connectors.map((connector) => (
													<div className="tagcloud mt-1 col" key={connector.id}>
														<Button
															type="button"
															style={{
																backgroundColor: "white",
																borderRadius: "16px",
																color: "#3c0987",
															}}
															onClick={() => connect({ connector })}
															className=" font-medium rounded-lg text-sm px-3 py-2.5 text-center inline-flex items-center ">
															<img
																loading="lazy"
																width="35"
																height="30"
																// src={wal}
																src={connectorImages[connector.name]}
																alt="meta"
																marginLeft="30"
																marginRight="20"
															/>
															<h5 className="mr-3 ml-2 mt-2"
																style={{ marginRight: "3px", marginLeft: "13px" }}
																key={connector.uid}
															>

																{connector.name}
															</h5>
														</Button>
													</div>
												))}
											</div>
										</div>
									</div>
									<div className="modal-body">
										<label className="form-label d-block fw-bold">Select your favourite wallet to Access Dashbaord.</label>
									</div>
									<div className="modal-footer">
										<a>By connecting a wallet, you agree to Uniswap Labs' Terms of Service and consent to its Privacy Policy. </a>
										<button type="button" className="btn btn-secondary" onClick={() => setContactModal(false)}>Close</button>
									</div>
								</div>
							</Modal>
						</>
					)}
				</div>
			</div>
		</>
	)
}
export default Home;


