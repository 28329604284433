import React, { useState, useEffect } from "react";
import { useAppDispatch } from "../../../reducer/store";
import { FILTERPROP } from "../../../slices/filter";
import { Dropdown } from 'react-bootstrap';

const CountryDropdown = () => {
  const [location, setlocation] = useState("");
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(FILTERPROP(location));
  }
  );


  const handleSelect = (eventKey) => {
    setlocation(eventKey);
  };

  return (
   
    <div className="input-group ">
      <button className="btn btn-outline-primary form-control" >Property Location</button>
      <Dropdown className="d-flex" onSelect={handleSelect}>
        <Dropdown.Toggle as="button" className="btn btn-primary btn-outline-primary left-radius ">{location}</Dropdown.Toggle>
          <Dropdown.Menu align="end" style={{zIndex: "3" ,overflowY:"scroll",  maxHeight: "calc(65vh - 150px)", backgroundColor:"#ebebeb"}}>
            <Dropdown.Item eventKey="">All Markets</Dropdown.Item >
            <Dropdown.Item eventKey="El Salvador">El Salvador</Dropdown.Item >
            <Dropdown.Item eventKey="Alabama">Alabama</Dropdown.Item >
            <Dropdown.Item eventKey="Alaska">Alaska</Dropdown.Item >
            <Dropdown.Item eventKey="Arizona">Arizona</Dropdown.Item >
            <Dropdown.Item eventKey="Arkansas">Arkansas</Dropdown.Item >
            <Dropdown.Item eventKey="California">California</Dropdown.Item >
            <Dropdown.Item eventKey="Colorado">Colorado</Dropdown.Item >
            <Dropdown.Item eventKey="Connecticut">Connecticut</Dropdown.Item >
            <Dropdown.Item eventKey="Delaware">Delaware</Dropdown.Item >
            <Dropdown.Item eventKey="Florida">Florida</Dropdown.Item >
            <Dropdown.Item eventKey="Georgia">Georgia</Dropdown.Item >
            <Dropdown.Item eventKey="Hawaii">Hawaii</Dropdown.Item >
            <Dropdown.Item eventKey="Idaho">Idaho</Dropdown.Item >
            <Dropdown.Item eventKey="Illinois">Illinois</Dropdown.Item >
            <Dropdown.Item eventKey="Indiana">Indiana</Dropdown.Item >
            <Dropdown.Item eventKey="Iowa">Iowa</Dropdown.Item >
            <Dropdown.Item eventKey="Kansas">Kansas</Dropdown.Item >
            <Dropdown.Item eventKey="Kentucky">Kentucky</Dropdown.Item >
            <Dropdown.Item eventKey="Louisiana">Louisiana</Dropdown.Item >
            <Dropdown.Item eventKey="Maine">Maine</Dropdown.Item >
            <Dropdown.Item eventKey="Maryland">Maryland</Dropdown.Item >
            <Dropdown.Item eventKey="Massachusetts">Massachusetts</Dropdown.Item >
            <Dropdown.Item eventKey="Michigan">Michigan</Dropdown.Item >
            <Dropdown.Item eventKey="Minnesota">Minnesota</Dropdown.Item >
            <Dropdown.Item eventKey="Mississippi">Mississippi</Dropdown.Item >
            <Dropdown.Item eventKey="Missouri">Missouri</Dropdown.Item >
            <Dropdown.Item eventKey="Montana">Montana</Dropdown.Item >
            <Dropdown.Item eventKey="Nebraska">Nebraska</Dropdown.Item >
            <Dropdown.Item eventKey="Nevada">Nevada</Dropdown.Item >
            <Dropdown.Item eventKey="New Hampshire">New Hampshire</Dropdown.Item >
            <Dropdown.Item eventKey="New Jersey">New Jersey</Dropdown.Item >
            <Dropdown.Item eventKey="New Mexico">New Mexico</Dropdown.Item >
            <Dropdown.Item eventKey="New York">New York</Dropdown.Item >
            <Dropdown.Item eventKey="North Carolina">North Carolina</Dropdown.Item >
            <Dropdown.Item eventKey="North Dakota">North Dakota</Dropdown.Item >
            <Dropdown.Item eventKey="Ohio">Ohio</Dropdown.Item >
            <Dropdown.Item eventKey="Oklahoma">Oklahoma</Dropdown.Item >
            <Dropdown.Item eventKey="Oregon">Oregon</Dropdown.Item >
            <Dropdown.Item eventKey="Pennsylvania">Pennsylvania</Dropdown.Item >
            <Dropdown.Item eventKey="Rhode Island">Rhode Island</Dropdown.Item >
            <Dropdown.Item eventKey="South Carolina">South Carolina</Dropdown.Item >
            <Dropdown.Item eventKey="South Dakota">South Dakota</Dropdown.Item >
            <Dropdown.Item eventKey="Tennessee">Tennessee</Dropdown.Item >
            <Dropdown.Item eventKey="Texas">Texas</Dropdown.Item >
            <Dropdown.Item eventKey="Utah">Utah</Dropdown.Item >
            <Dropdown.Item eventKey="Vermont">Vermont</Dropdown.Item >
            <Dropdown.Item eventKey="Virginia">Virginia</Dropdown.Item >
            <Dropdown.Item eventKey="Washington">Washington</Dropdown.Item >
            <Dropdown.Item eventKey="West Virginia">West Virginia</Dropdown.Item >
            <Dropdown.Item eventKey="Wisconsin">Wisconsin</Dropdown.Item >
            <Dropdown.Item eventKey="Wyoming">Wyoming</Dropdown.Item >
            <Dropdown.Item eventKey="District of Columbia">District of Columbia</Dropdown.Item >
            <Dropdown.Item eventKey="American Samoa">American Samoa</Dropdown.Item >
            <Dropdown.Item eventKey="Guam">Guam</Dropdown.Item >
            <Dropdown.Item eventKey="Northern Mariana Islands">
              Northern Mariana Islands
            </Dropdown.Item >
            <Dropdown.Item eventKey="Puerto Rico">Puerto Rico</Dropdown.Item >
            <Dropdown.Item eventKey="California"> U.S. Virgin Islands</Dropdown.Item >
          </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default CountryDropdown;
