import React from "react";

const Footr = () => {
	var d = new Date();
	return (
			<div className="footr out-footr" style={{ backgroundColor: "#D4EAF2" }}>
				<div className="copyright">
					<p>Copyright © Designed &amp; Developed by{" "}
						<a href="http://secondarydao.com/" target="_blank" rel="noreferrer">
							secondaryDAO
						</a>{" "}
						{d.getFullYear()}
					</p>
				</div>
			</div>
		// </div>
	);
};

export default Footr;
