/// Menu
import React, { Component, useContext, useEffect, useReducer, useState } from "react";
//import Metismenu from "metismenujs";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
import Collapse from 'react-bootstrap/Collapse';
import Button from 'react-bootstrap/Button';

/// Link
import { Link, NavLink } from "react-router-dom";

import { MenuList } from './Menu';
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { ThemeContext } from "../../../context/ThemeContext";
import { useSelector } from 'react-redux';

//import medal from "../../../images/medal.png";


// class MM extends Component {
// 	componentDidMount() {
// 		this.$el = this.el;
// 		this.mm = new Metismenu(this.$el);
// 	}
//   componentWillUnmount() {
//   }
//   render() {
//     return (
//       <div className="mm-wrapper">
//         <ul className="metismenu " ref={(el) => (this.el = el)}>
//           {this.props.children}
//         </ul>
//       </div>
//     );
//   }
// }

const reducer = (previousState, updatedState) => ({
  ...previousState,
  ...updatedState,
});

const initialState = {
  active: "",
  activeSubmenu: "",
}

const SideBar = () => {
  const auth = useSelector((state) => state.auth);
  const {
    iconHover,
    sidebarposition,
    headerposition,
    sidebarLayout,
  } = useContext(ThemeContext);
  const [state, setState] = useReducer(reducer, initialState);

  // let handleheartBlast = document.querySelector('.heart');
  // function heartBlast(){
  //   return handleheartBlast.classList.toggle("heart-blast");
  // }

  useEffect(() => {


  }, []);
  //For scroll
  const [hideOnScroll, setHideOnScroll] = useState(true)
  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y > prevPos.y
      if (isShow !== hideOnScroll) setHideOnScroll(isShow)
    },
    [hideOnScroll]
  )

  // Menu dropdown list 
  //const [active , setActive] = useState('');
  //const [activeSubmenu , setActiveSubmenu] = useState('');


  const handleMenuActive = status => {

    setState({ active: status });

    if (state.active === status) {

      setState({ active: "" });
    }

  }
  const handleSubmenuActive = (status) => {

    setState({ activeSubmenu: status })
    if (state.activeSubmenu === status) {
      setState({ activeSubmenu: "" })

    }

  }
  // Menu dropdown list End

  /// Path
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];
  
  const ListofMenu = [
    //Dashboard
    {
        title: 'Dashboard',
        classsChange: 'mm-collapse',		
        iconStyle: <i className="material-icons">grid_view</i>,
        to: '/dashboard',
        content: [
        ],
    },

    //Forms
    // {
    //     title: 'Properties',
    //     classsChange: 'mm-collapse',
    //     iconStyle: <i className="material-icons"> insert_drive_file </i>,
    //     content: [
    //         {
    //             title:'Property List',
    //             to: '/dashboard/propertylist',
    //         },
    //         {
    //             title:'Create Property',
    //             to: '/dashboard/propertyCreate',
    //         },
    //         {
    //             title:'Create Blog',
    //             to: '/dashboard/blogCreate',
    //         },
    //         {
    //             title:'Propery Form List',
    //             to: '/dashboard/listedPropRequest',
    //         },
    //         {
    //             title:'Listed Blogs',
    //             to: '/dashboard/bloglist',
    //         },
            
    //     ]
    // },


    {
      title: 'My Profile',
      to: '/dashboard/Profile/' + auth._id,
      classsChange: 'mm-collapse',
      iconStyle: <i className="material-icons"> insert_drive_file </i>,
      content: [

      ]
  },

    //Reports
    {
        title: 'Setting',
        classsChange: 'mm-collapse',
        to: '#',
        iconStyle: <i className="material-icons">description</i>,
        content: [
          
        ],
    },

]

  return (
    <div
      className={`deznav  border-right ${iconHover} ${sidebarposition.value === "fixed" &&
        sidebarLayout.value === "horizontal" &&
        headerposition.value === "static"
        ? hideOnScroll > 120
          ? "fixed"
          : ""
        : ""
        }`}
    >
      <PerfectScrollbar className="deznav-scroll">
        <ul className="metismenu" id="menu">
          {/* <Link to={"/dashboard/" + auth._id}>
            Dashboard
          </Link> */}
          {ListofMenu.map((data, index) => {
            let menuClass = data.classsChange;
            if (menuClass === "menu-title") {
              return (
                <li className={menuClass} key={index} >{data.title}</li>
              )
            } else {
              return (
                <li className={` ${state.active === data.title ? 'mm-active' : ''}`}
                  key={index}
                >

                  {data.content && data.content.length > 0 ?
                    <Link to={"#"}
                      className="has-arrow"
                      onClick={() => { handleMenuActive(data.title) }}
                    >
                      {data.iconStyle}
                      <span className="nav-text">{data.title}</span>
                    </Link>
                    :
                    <NavLink to={data.to} >
                      {data.iconStyle}
                      <span className="nav-text">{data.title}</span>
                    </NavLink>
                  }
                  <Collapse in={state.active === data.title ? true : false}>
                    <ul className={`${menuClass === "mm-collapse" ? "mm-show" : ""}`}>
                      {data.content && data.content.map((data, index) => {
                        return (
                          <li key={index}
                            className={`${state.activeSubmenu === data.title ? "mm-active" : ""}`}
                          >
                            {data.content && data.content.length > 0 ?
                              <>
                                <NavLink to={data.to} className={data.hasMenu ? 'has-arrow' : ''}
                                  onClick={() => { handleSubmenuActive(data.title) }}
                                >
                                  {data.title}
                                </NavLink>
                                <Collapse in={state.activeSubmenu === data.title ? true : false}>
                                  <ul className={`${menuClass === "mm-collapse" ? "mm-show" : ""}`}>
                                    {data.content && data.content.map((data, index) => {
                                      return (
                                        <>
                                          <li key={index}>
                                            <Link className={`${path === data.to ? "mm-active" : ""}`} to={data.to}>{data.title}</Link>
                                          </li>
                                        </>
                                      )
                                    })}
                                  </ul>
                                </Collapse>
                              </>
                              :
                              <Link to={data.to}>
                                {data.title}
                              </Link>
                            }

                          </li>

                        )
                      })}
                    </ul>
                  </Collapse>
                </li>
              )
            }
          })}
        </ul>
      </PerfectScrollbar>
    </div>
  );
};

export default SideBar;
