import React, { useEffect } from "react";
import { Link } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../../reducer/store';
import Marquee from "react-fast-marquee";
import CountryDropdown from './search/CountryDropdown';
import LocationDropdown from './search/LocationDropdown';
import ReactSlider from 'react-slider';
import { fetchRentDataByYear } from "../../slices/rentSlice";


const MarketPage = () => {
    const dispatch = useAppDispatch();
    const { items: data, status } = useAppSelector((state) => state.products);
    const { Indata: datas } = useAppSelector((state) => state.rent);
    const { Country } = useAppSelector((state) => state.FiterProperty);
    const { Type } = useAppSelector((state) => state.FilterTypeProp);

    useEffect(() => {
        if (data.length > 0) {
            const fetchda = async () => {
                data && data?.map(async (item) => (
                    dispatch(fetchRentDataByYear(
                        {
                            "propertyId": item._id,
                            "year": new Date().getFullYear(),
                        }))
                ));
            }
            fetchda();
        }
    }, [data.length])

    return (
            <div className="row" style={{ marginTop: "40px" }}>
                <div className="col-xl-12">
                    <div className="carde">
                        <div className="carde-body pb-2">
                            <h1 className="text-center no-border font-w600 fs-60 mt-2 text-black">
                                <span className="text-primary">Buy</span> and
                                <span className="text-danger"> Sell</span> Real Estate at<br />
                                SecondaryDAO with no extra fees</h1>
                            <h4 className="text-center text-black">
                                Invest in real estate tokenizations and benefit from fractional ownership.
                            </h4>
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="text-center mt-3 row justify-content-center">
                                        <div className="col-xl-12 mb-3 ">
                                            <div className="row text-center justify-content-center">
                                                <div className="col-xl-3 mt-2">
                                                    <LocationDropdown />
                                                </div>
                                                <div className="col-xl-3 mt-2">
                                                    <CountryDropdown />
                                                </div>
                                                <div className="col-xl-3 mt-2" >
                                                    <div className="form-control">
                                                        <div className="slider-wrapper " >
                                                            {<ReactSlider
                                                                min={0}
                                                                max={99}
                                                                defaultValue={0}
                                                                className="pro-Slider"
                                                                renderThumb={(props, state) =>
                                                                    <div {...props} key="unique-key">
                                                                        {`${state.valueNow}%`}
                                                                    </div>}
                                                            />}
                                                        </div>
                                                    </div>
                                                    <span>Projected Rental Yeild</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-xxl-12">
                    <div className="overflow-hidden bg-transparent dz-crypto-scroll shadow-none">
                        <div className="js-conveyor-example">
                            <ul className="crypto-list" id="crypto-webticker">
                                <Marquee
                                    speed={80}
                                    loop={0}
                                    pauseOnHover={true}
                                >
                                    {data.map((item, index) => (

                                        <div className="card overflow-hidden" key={index} style={{ marginLeft: "20px" }}>
                                            <div className="card-body d-flex align-items-center" style={{ zIndex: 6 }} >
                                                <div className="me-4">
                                                    <p className="mb-2 fs-13"><i className="fa fa-caret-up scale5 me-2 text-success" aria-hidden="true"></i>{new Date(item.createdAt).toDateString()}</p>
                                                    <h4 className="heading mb-0">{item.name}</h4>
                                                </div>
                                                <div className="coin-img">
                                                    <img width="42" height="42" src={item.image?.url} className="img-fluid" alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </Marquee>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-xl-12">
                    <div className="row">
                        {status === "success" ? (
                            <>
                                {Country != 0 ? (
                                    <>
                                        {data &&
                                            data
                                                ?.filter((key) => key.location == Country)
                                                .map((item) => (
                                                    <div className="col-xxl-4 col-xl-6 col-lg-6 col-md-6 col-sm-6" key={item._id}>
                                                        <div className="cards">
                                                            <div className="card-body">
                                                                <div className="row m-b-30">
                                                                    <div className="col-md-5" style={{ width: '20px', height: '20px' }}>
                                                                        <div className="new-arrival-product mb-4 mb-xxl-4 mb-md-0">
                                                                            <Link to={"/propertydetails/" + item._id}>
                                                                                <div className="new-arrivals-img-contnent" >
                                                                                    <img className="img-fluid" src={item.image?.url} alt="" />
                                                                                </div>
                                                                            </Link>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-7">
                                                                        <div className="new-arrival-content position-relative">
                                                                            <h4>
                                                                                <Link to={"/propertydetails/" + item._id}>
                                                                                    {item.name}
                                                                                    <span style={{ fontSize: "10px", color: "#89888a" }}>{" "}{item.location}</span>
                                                                                </Link>
                                                                            </h4>
                                                                            <div className="comment-review star-rating">
                                                                                <ul>
                                                                                    <li>
                                                                                        Property Status:{" "}
                                                                                        <span className="item" style={{ fontSize: "13px", }}>
                                                                                            {" "}
                                                                                            {item.isStartSelling == false && item.isReStartSelling == false ?
                                                                                                (<span style={{ color: "red" }}>Closed</span>) :
                                                                                                (<span style={{ color: "#00a389" }}>Active {" "}<i className="fa fa-check-circle text-success" /></span>)}
                                                                                        </span>
                                                                                    </li>
                                                                                </ul>

                                                                                <div className="product-review"
                                                                                    style={{
                                                                                        color: "#9467fe",
                                                                                        fontSize: "20px",
                                                                                        width: "100%",
                                                                                        height: "100%",
                                                                                        backgroundColor: "#FFFFFF",
                                                                                    }}>
                                                                                    <p className="review-text">Available Tokens
                                                                                        <span className=" price">
                                                                                            {" "} {(item.tokenBlance / 1e18).toFixed(2)}
                                                                                        </span> </p>
                                                                                </div>
                                                                            </div>

                                                                            <table style={{
                                                                                width: "100%",
                                                                                lineHeight: "19.5px",
                                                                                marginTop: "17px",
                                                                            }}>
                                                                                <tbody>
                                                                                    <tr
                                                                                        style={{ backgroundColor: "#FFFFFF" }}
                                                                                    >
                                                                                        <td
                                                                                            style={{
                                                                                                verticalAlign: "baseline",
                                                                                                textAlign: "center",
                                                                                                borderRight: "2px solid #1C2E9E",
                                                                                            }}
                                                                                        ><h6
                                                                                            style={{ marginBottom: "0px", fontFamily: "Arial", }}>
                                                                                                Token Price</h6>
                                                                                            <h4 style={{ marginBottom: "0px", fontFamily: "Arial", }}
                                                                                            >${(item.tokenPrice / 1e8).toFixed(2)}
                                                                                            </h4>
                                                                                        </td>
                                                                                        <td style={{
                                                                                            verticalAlign: "baseline",
                                                                                            textAlign: "center",
                                                                                            fontFamily: "Arial",
                                                                                        }}>
                                                                                            <h6
                                                                                                style={{ marginBottom: "0px", fontFamily: "Arial", marginLeft: "5px", }}>
                                                                                                Market Cap
                                                                                            </h6>
                                                                                            <h4 style={{ marginBottom: "0px", fontFamily: "Arial", marginLeft: "5px", }}>
                                                                                                ${((item.totalSupply * item.tokenPrice) / 1e18).toFixed(1)}
                                                                                            </h4>
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                            <table style={{
                                                                                width: "100%",
                                                                                lineHeight: "19.5px",
                                                                                marginTop: "17px"
                                                                            }}>
                                                                                <tbody>
                                                                                    <tr
                                                                                        style={{ backgroundColor: "#FFFFFF" }}
                                                                                    >
                                                                                        <td
                                                                                            style={{
                                                                                                verticalAlign: "baseline",
                                                                                                textAlign: "left",
                                                                                                fontFamily: "Arial",
                                                                                                fontWeight: "55px"
                                                                                            }}
                                                                                        ><p
                                                                                            style={{ marginBottom: "0px", fontFamily: "Arial", }}>
                                                                                                Projected Rental Yield
                                                                                            </p>
                                                                                        </td>
                                                                                        <td style={{
                                                                                            verticalAlign: "baseline",
                                                                                            textAlign: "right",
                                                                                            fontFamily: "Arial",
                                                                                        }}>
                                                                                            <div style={{ marginBottom: "0px", fontFamily: "Arial", }}>
                                                                                                {datas &&
                                                                                                    datas
                                                                                                        ?.filter((key) => key.propertyId == item._id)
                                                                                                        .map((items) => (
                                                                                                            <div key={items._id}>
                                                                                                                {items.rentalYield > 0 ? <>{(items.rentalYield).toFixed(1)}%</> : <>0.00%</>}
                                                                                                            </div>
                                                                                                        ))
                                                                                                }
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                            <table style={{
                                                                                width: "100%",
                                                                                lineHeight: "19.5px",
                                                                                marginTop: "17px"
                                                                            }}>
                                                                                <tbody>
                                                                                    <tr
                                                                                        style={{ backgroundColor: "#FFFFFF" }}
                                                                                    >
                                                                                        <td
                                                                                            style={{
                                                                                                verticalAlign: "baseline",
                                                                                                textAlign: "left",
                                                                                                fontFamily: "Arial",
                                                                                                fontWeight: "55px"
                                                                                            }}
                                                                                        ><p
                                                                                            style={{ marginBottom: "0px", fontFamily: "Arial", }}>
                                                                                                CoC Return
                                                                                            </p>
                                                                                        </td>
                                                                                        <td style={{
                                                                                            verticalAlign: "baseline",
                                                                                            textAlign: "right",
                                                                                            fontFamily: "Arial",
                                                                                        }}>
                                                                                            <div style={{ marginBottom: "0px", fontFamily: "Arial", }}>
                                                                                                {datas &&
                                                                                                    datas
                                                                                                        ?.filter((key) => key.propertyId == item._id)
                                                                                                        .map((items) => (
                                                                                                            <div key={items._id}>
                                                                                                                {items.annualCoC > 0 ? <>{(items.annualCoC).toFixed(1)}%</> : <>0.00%</>}
                                                                                                            </div>
                                                                                                        ))
                                                                                                }
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                            <div className="mt-2 text-end">
                                                                                <Link to={"/propertydetails/" + item._id}
                                                                                    type="button" className="btn btn-outline-primary" data-mdb-ripple-init data-mdb-ripple-color="dark">
                                                                                    View Property
                                                                                </Link>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                    </>
                                ) : (
                                    <>
                                        {Type != 0 ? (
                                            <>
                                                {data &&
                                                    data
                                                        ?.filter((key) => key.propertytype == Type)
                                                        .map((item) => (
                                                            <div className="col-xxl-4 col-xl-6 col-lg-6 col-md-6 col-sm-6" key={item._id}>
                                                                <div className="cards">
                                                                    <div className="card-body">
                                                                        <div className="row m-b-30">
                                                                            <div className="col-md-5">
                                                                                <div className="new-arrival-product mb-4 mb-xxl-4 mb-md-0">
                                                                                    <Link to={"/propertydetails/" + item._id}>
                                                                                        <div className="new-arrivals-img-contnent">
                                                                                            <img className="img-fluid" src={item.image?.url} alt="" />
                                                                                        </div>
                                                                                    </Link>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-7">
                                                                                <div className="new-arrival-content position-relative">
                                                                                    <h4>
                                                                                        <Link to={"/propertydetails/" + item._id}>
                                                                                            {item.name}
                                                                                            <span style={{ fontSize: "10px", color: "#89888a" }}>{" "}{item.location}</span>
                                                                                        </Link>
                                                                                    </h4>
                                                                                    <div className="comment-review star-rating">
                                                                                        <ul>
                                                                                            <li>
                                                                                                Property Status:{" "}
                                                                                                <span className="item" style={{ fontSize: "13px", }}>
                                                                                                    {" "}
                                                                                                    {item.isStartSelling == false && item.isReStartSelling == false ?
                                                                                                        (<span style={{ color: "red" }}>Closed</span>) :
                                                                                                        (<span style={{ color: "#00a389" }}>Active {" "}<i className="fa fa-check-circle text-success" /></span>)}
                                                                                                </span>
                                                                                            </li>
                                                                                        </ul>

                                                                                        <div className="product-review"
                                                                                            style={{
                                                                                                color: "#9467fe",
                                                                                                fontSize: "20px",
                                                                                                width: "100%",
                                                                                                height: "100%",
                                                                                                backgroundColor: "#FFFFFF",
                                                                                            }}>
                                                                                            <p className="review-text">Available Token
                                                                                                <span className=" price">
                                                                                                    {" "} {(item.tokenBlance / 1e18).toFixed(2)}
                                                                                                </span> </p>
                                                                                        </div>
                                                                                    </div>

                                                                                    <table style={{
                                                                                        width: "100%",
                                                                                        lineHeight: "19.5px",
                                                                                        marginTop: "17px",
                                                                                    }}>
                                                                                        <tbody>
                                                                                            <tr
                                                                                                style={{ backgroundColor: "#FFFFFF" }}
                                                                                            >
                                                                                                <td
                                                                                                    style={{
                                                                                                        verticalAlign: "baseline",
                                                                                                        textAlign: "center",
                                                                                                        borderRight: "2px solid #1C2E9E",
                                                                                                    }}
                                                                                                ><h6
                                                                                                    style={{ marginBottom: "0px", fontFamily: "Arial", }}>
                                                                                                        Token Price</h6>
                                                                                                    <h4 style={{ marginBottom: "0px", fontFamily: "Arial", }}
                                                                                                    >${(item.tokenPrice / 1e8).toFixed(2)}
                                                                                                    </h4>
                                                                                                </td>
                                                                                                <td style={{
                                                                                                    verticalAlign: "baseline",
                                                                                                    textAlign: "center",
                                                                                                    fontFamily: "Arial",
                                                                                                }}>
                                                                                                    <h6
                                                                                                        style={{ marginBottom: "0px", fontFamily: "Arial", marginLeft: "5px", }}>
                                                                                                        Market Cap
                                                                                                    </h6>
                                                                                                    <h4 style={{ marginBottom: "0px", fontFamily: "Arial", marginLeft: "5px", }}>
                                                                                                        ${((item.totalSupply * item.tokenPrice) / 1e18).toFixed(1)}
                                                                                                    </h4>
                                                                                                </td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                    <table style={{
                                                                                        width: "100%",
                                                                                        lineHeight: "19.5px",
                                                                                        marginTop: "17px"
                                                                                    }}>
                                                                                        <tbody>
                                                                                            <tr
                                                                                                style={{ backgroundColor: "#FFFFFF" }}
                                                                                            >
                                                                                                <td
                                                                                                    style={{
                                                                                                        verticalAlign: "baseline",
                                                                                                        textAlign: "left",
                                                                                                        fontFamily: "Arial",
                                                                                                        fontWeight: "55px"
                                                                                                    }}
                                                                                                ><p
                                                                                                    style={{ marginBottom: "0px", fontFamily: "Arial", }}>
                                                                                                        Projected Rental Yield
                                                                                                    </p>
                                                                                                </td>
                                                                                                <td style={{
                                                                                                    verticalAlign: "baseline",
                                                                                                    textAlign: "right",
                                                                                                    fontFamily: "Arial",
                                                                                                }}>
                                                                                                    <div style={{ marginBottom: "0px", fontFamily: "Arial", }}>
                                                                                                        {datas &&
                                                                                                            datas
                                                                                                                ?.filter((key) => key.propertyId == item._id)
                                                                                                                .map((items) => (
                                                                                                                    <div key={items._id}>
                                                                                                                        {items.rentalYield > 0 ? <>{(items.rentalYield).toFixed(1)}%</> : <>0.00%</>}
                                                                                                                    </div>
                                                                                                                ))
                                                                                                        }
                                                                                                    </div>
                                                                                                </td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                    <table style={{
                                                                                        width: "100%",
                                                                                        lineHeight: "19.5px",
                                                                                        marginTop: "17px"
                                                                                    }}>
                                                                                        <tbody>
                                                                                            <tr
                                                                                                style={{ backgroundColor: "#FFFFFF" }}
                                                                                            >
                                                                                                <td
                                                                                                    style={{
                                                                                                        verticalAlign: "baseline",
                                                                                                        textAlign: "left",
                                                                                                        fontFamily: "Arial",
                                                                                                        fontWeight: "55px"
                                                                                                    }}
                                                                                                ><p
                                                                                                    style={{ marginBottom: "0px", fontFamily: "Arial", }}>
                                                                                                        CoC Return
                                                                                                    </p>
                                                                                                </td>
                                                                                                <td style={{
                                                                                                    verticalAlign: "baseline",
                                                                                                    textAlign: "right",
                                                                                                    fontFamily: "Arial",
                                                                                                }}>
                                                                                                    <div style={{ marginBottom: "0px", fontFamily: "Arial", }}>
                                                                                                        {datas &&
                                                                                                            datas
                                                                                                                ?.filter((key) => key.propertyId == item._id)
                                                                                                                .map((items) => (
                                                                                                                    <div key={items._id}>
                                                                                                                        {items.annualCoC > 0 ? <>{(items.annualCoC).toFixed(1)}%</> : <>0.00%</>}
                                                                                                                    </div>
                                                                                                                ))
                                                                                                        }
                                                                                                    </div>
                                                                                                </td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                    <div className="mt-2 text-end">
                                                                                        <Link to={"/propertydetails/" + item._id}
                                                                                            type="button" className="btn btn-outline-primary" data-mdb-ripple-init data-mdb-ripple-color="dark">
                                                                                            View Property
                                                                                        </Link>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                            </>
                                        ) : (
                                            <>
                                                {data && data?.map((item) => (
                                                    <div className="col-xxl-4 col-xl-6 col-lg-6 col-md-6 col-sm-6" key={item._id}>
                                                        <div className="cards">
                                                            <div className="cards-body">
                                                                <div className="row m-b-30">
                                                                    <div className="col-md-5">
                                                                        <div className="new-arrival-product mb-4 mb-xxl-4 mb-md-0">
                                                                            <Link to={"/propertydetails/" + item._id}>
                                                                                <div className="new-arrivals-img-contnent">
                                                                                    <img className="img-fluid" src={item.image?.url} alt="" />
                                                                                </div>
                                                                            </Link>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-7">
                                                                        <div className="new-arrival-content position-relative">
                                                                            <h4>
                                                                                <Link to={"/propertydetails/" + item._id}>
                                                                                    {item.name}
                                                                                    <span style={{ fontSize: "10px", color: "#89888a" }}>{" "}{item.location}</span>
                                                                                </Link>
                                                                            </h4>
                                                                            <div className="comment-review star-rating">
                                                                                <ul>
                                                                                    <li>
                                                                                        Property Status:{" "}
                                                                                        <span className="item" style={{ fontSize: "13px", }}>
                                                                                            {" "}
                                                                                            {item.isStartSelling == false && item.isReStartSelling == false ?
                                                                                                (<span style={{ color: "red" }}>Closed</span>) :
                                                                                                (<span style={{ color: "#00a389" }}>Active {" "}<i className="fa fa-check-circle text-success" /></span>)}
                                                                                        </span>
                                                                                    </li>
                                                                                </ul>

                                                                                <div className="product-review"
                                                                                    style={{
                                                                                        color: "#9467fe",
                                                                                        fontSize: "20px",
                                                                                        width: "100%",
                                                                                        height: "100%",
                                                                                        backgroundColor: "#FFFFFF",
                                                                                    }}>
                                                                                    <p className="review-text">Available Token
                                                                                        <span className=" price">
                                                                                            {" "} {(item.tokenBlance / 1e18).toFixed(2)}
                                                                                        </span> </p>
                                                                                </div>
                                                                            </div>

                                                                            <table style={{
                                                                                width: "100%",
                                                                                lineHeight: "19.5px",
                                                                                marginTop: "17px",
                                                                            }}>
                                                                                <tbody>
                                                                                    <tr
                                                                                        style={{ backgroundColor: "#FFFFFF" }}
                                                                                    >
                                                                                        <td
                                                                                            style={{
                                                                                                verticalAlign: "baseline",
                                                                                                textAlign: "center",
                                                                                                borderRight: "2px solid #1C2E9E",
                                                                                            }}
                                                                                        ><h6
                                                                                            style={{ marginBottom: "0px", fontFamily: "Arial", }}>
                                                                                                Token Price</h6>
                                                                                            <h4 style={{ marginBottom: "0px", fontFamily: "Arial", }}
                                                                                            >${(item.tokenPrice / 1e8).toFixed(2)}
                                                                                            </h4>
                                                                                        </td>
                                                                                        <td style={{
                                                                                            verticalAlign: "baseline",
                                                                                            textAlign: "center",
                                                                                            fontFamily: "Arial",
                                                                                        }}>
                                                                                            <h6
                                                                                                style={{ marginBottom: "0px", fontFamily: "Arial", marginLeft: "5px", }}>
                                                                                                Market Cap
                                                                                            </h6>
                                                                                            <h4 style={{ marginBottom: "0px", fontFamily: "Arial", marginLeft: "5px", }}>
                                                                                                ${((item.totalSupply * item.tokenPrice) / 1e18).toFixed(1)}
                                                                                            </h4>
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                            <table style={{
                                                                                width: "100%",
                                                                                lineHeight: "19.5px",
                                                                                marginTop: "17px"
                                                                            }}>
                                                                                <tbody>
                                                                                    <tr
                                                                                        style={{ backgroundColor: "#FFFFFF" }}
                                                                                    >
                                                                                        <td
                                                                                            style={{
                                                                                                verticalAlign: "baseline",
                                                                                                textAlign: "left",
                                                                                                fontFamily: "Arial",
                                                                                                fontWeight: "55px"
                                                                                            }}
                                                                                        ><p
                                                                                            style={{ marginBottom: "0px", fontFamily: "Arial", }}>
                                                                                                Projected Rental Yield
                                                                                            </p>
                                                                                        </td>
                                                                                        <td style={{
                                                                                            verticalAlign: "baseline",
                                                                                            textAlign: "right",
                                                                                            fontFamily: "Arial",
                                                                                        }}>
                                                                                            <div style={{ marginBottom: "0px", fontFamily: "Arial", }}>
                                                                                                {datas &&
                                                                                                    datas
                                                                                                        ?.filter((key) => key.propertyId == item._id)
                                                                                                        .map((items) => (
                                                                                                            <div key={items._id}>
                                                                                                                {items.rentalYield > 0 ? <>{(items.rentalYield).toFixed(1)}%</> : <>0.00%</>}
                                                                                                            </div>
                                                                                                        ))
                                                                                                }
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                            <table style={{
                                                                                width: "100%",
                                                                                lineHeight: "19.5px",
                                                                                marginTop: "17px"
                                                                            }}>
                                                                                <tbody>
                                                                                    <tr
                                                                                        style={{ backgroundColor: "#FFFFFF" }}
                                                                                    >
                                                                                        <td
                                                                                            style={{
                                                                                                verticalAlign: "baseline",
                                                                                                textAlign: "left",
                                                                                                fontFamily: "Arial",
                                                                                                fontWeight: "55px"
                                                                                            }}
                                                                                        ><p
                                                                                            style={{ marginBottom: "0px", fontFamily: "Arial", }}>
                                                                                                CoC Return
                                                                                            </p>
                                                                                        </td>
                                                                                        <td style={{
                                                                                            verticalAlign: "baseline",
                                                                                            textAlign: "right",
                                                                                            fontFamily: "Arial",
                                                                                        }}>
                                                                                            <div style={{ marginBottom: "0px", fontFamily: "Arial", }}>
                                                                                                {datas &&
                                                                                                    datas
                                                                                                        ?.filter((key) => key.propertyId == item._id)
                                                                                                        .map((items) => (
                                                                                                            <div key={items._id}>
                                                                                                                {items.annualCoC > 0 ? <>{(items.annualCoC).toFixed(1)}%</> : <>0.00%</>}
                                                                                                            </div>
                                                                                                        ))
                                                                                                }
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                            <div className="mt-2 text-end">
                                                                                <Link to={"/propertydetails/" + item._id}
                                                                                    type="button" className="btn btn-outline-primary" data-mdb-ripple-init data-mdb-ripple-color="dark">
                                                                                    View Property
                                                                                </Link>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </>
                                        )}
                                    </>
                                )}
                            </>
                        ) : status === "pending" ? (
                            <p>Loading...</p>
                        ) : (
                            <p>Unexpected error occurred...</p>
                        )}
                    </div>

                </div>
            </div>
    )
}
export default MarketPage;