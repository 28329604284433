import React, { Fragment, useReducer } from "react";
import { Link } from "react-router-dom";
import PageTitle from "../layouts/PageTitle";
import { propertyDelete } from "../../slices/propertySlice";
// import { Formik } from "formik";
// import * as Yup from "yup";
import { Modal } from "react-bootstrap";
import { useAppSelector, useAppDispatch } from '../../reducer/store';

import avatar1 from "../../images/product/1.jpg";



const init = false;
const reducer = (state, action) => {
  if (action.type === 'reviewModal') {
    return { ...state, reviewModal: !state.reviewModal }
  }
  return state;
}



const ListedPropRequest = () => {
  const dispatchs = useAppDispatch();
  const [state, dispatch] = useReducer(reducer, init);
  const { items: data } = useAppSelector((state) => state.propLLC);

  const handleDelete = (id) => {
    dispatchs(propertyDelete(id));
  };

  return (
    <Fragment>
      <PageTitle
        activeMenu="Listed Request"
        motherMenu="Properties"
        pageContent="Validation"
      />
      <div className="row">
        {data &&
          data?.map((per) => (
            <div key={per._id} className="col-lg-12 col-xl-6">
              <div className="card">
                <div className="card-body ">
                  <div className="row m-b-30">
                    <div className="col-md-5">
                      <div className="new-arrival-product mb-4 mb-xxl-4 mb-md-0">
                        <div className="new-arrivals-img-contnent">
                          <h4>
                            Name: {" "}{per.corpname}{" "}
                            <i className="fa fa-check-circle text-success" />
                          </h4>
                          <p>
                            Date:{" "}
                            <span className="item">
                              {" "}
                              {new Date(per.createdAt).toDateString()}{" "}
                              {/* <i className="fa fa-check-circle text-success" /> */}
                            </span>
                          </p>
                          <p>ID: {" "}{per.corpId}</p>
                          <p>Type: {" "}{per.corptype}</p>
                          <p>State: {" "}{per.regState}</p>
                          <p>EIN: {" "}{per.ein}</p>
                          <p>Rent: {" "}{per.rent}</p>
                          <p className="text-content">
                            {/* Address: {per.address} */}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-7">
                      <div className="new-arrival-content position-relative">
                        <p>Status: {" "}{" "}{per.propstatus}</p>
                        <p>Building Type:{" "}{" "} {per.buildingtype}</p>
                        <p>Property Address:{" "}{" "} {per.propaddress}</p>
                        <p>Property Address:{" "} {" "}{per.propaddress}</p>
                        <p>
                          Mailing Address:{" "}{" "}
                          <span className="item">{per.mailingAddress}</span>{" "}
                        </p>
                        <p>Agent Address:{" "} {" "}{per.regAgentAddress}</p>
                      </div>
                      <div className=" d-flex align-items-center justify-content-between">
                        <div>
                        </div>
                        <div className='ms-4'>
                          <button className="btn btn-success fs-8"
                            onClick={() => handleDelete(per._id)} >
                            Delete
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* review */}
              <Modal show={state.reviewModal} onHide={() => dispatch({ type: 'reviewModal' })} className="modal fade" id="reviewModal">
                <>
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">Review</h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-dismiss="modal"
                        onClick={() => dispatch({ type: 'reviewModal' })}
                      >
                      </button>
                    </div>
                    <div className="modal-body">
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();

                          dispatch({ type: 'reviewModal' })
                        }}
                      >
                        <div className="text-center mb-4">
                          <img
                            className="img-fluid rounded"
                            width={78}
                            src={avatar1}
                            alt="DexignZone"
                          />
                        </div>
                        <div className="form-group">
                          <div className="rating-widget mb-4 text-center">
                            {/* Rating Stars Box */}
                            <div className="rating-stars">
                              <ul
                                id="stars"
                                className="d-flex justify-content-center align-items-center"
                              >
                                <li>
                                  <i className="fa fa-star me-1" />
                                </li>{" "}
                                <li>
                                  <i className="fa fa-star me-1" />
                                </li>{" "}
                                <li>
                                  <i className="fa fa-star me-1" />
                                </li>{" "}
                                <li>
                                  <i className="fa fa-star me-1" />
                                </li>{" "}
                                <li>
                                  <i className="fa fa-star" />
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="form-group mb-3">
                          <textarea
                            className="form-control"
                            placeholder="Comment"
                            rows={5}
                            defaultValue={""}
                          />
                        </div>
                        <button className="btn btn-success btn-block">RATE</button>
                      </form>
                    </div>
                  </div>
                </>
              </Modal>
            </div>

          ))}
      </div>
    </Fragment>
  );
};

export default ListedPropRequest;
