import React, { useState, Fragment } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import {  useAppDispatch} from '../../reducer/store';
import { fileUpload } from "../../slices/blogSlice";
import { blogCreate } from "../../slices/blogSlice";
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import PageTitle from "../layouts/PageTitle";

const BlogCreate = () => {
  const dispatch = useAppDispatch();
  const [name, setname] = useState("");
  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("");
  const [file, setFile] = useState("");
  const { createStatus } = useSelector((state) => state.blogdb);


  const handleSubmit = async (e) => {
    e.preventDefault();
    const newPost = {
      title,
      desc,
      //   name: list.name,
      name,
    };
    if (file) {
      const data = new FormData();
      const filename = Date.now() + file.name;
      data.append("name", filename);
      data.append("file", file);
      newPost.photo = filename;
      dispatch(
        fileUpload(data)
      )

    }
    dispatch(
      blogCreate(newPost)
    )
  };


  return (
    <Fragment>
      <PageTitle activeMenu="Blogs" motherMenu="Create" pageContent="Pickers" />
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-xl-9 col-lg-8">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-6 mb-2">
                  <div className="form-group mb-3">
                    <label htmlFor="fileInput">
                      <i className="writeIcon fas fa-plus"></i>
                    </label>
                    <input type="file" id="fileInput" accept="image/*"
                      onChange={(e) => setFile(e.target.files[0])}
                    />
                    {/* <label className="text-label">Property Picture*</label>
                  <input id="imgUpload" accept="image/*" type="file"
                    onChange={handleProductImageUpload}
                    required /> */}
                  </div>
                </div>
                <div className="col-lg-6 mb-2">
                  <div className="form-group mb-3">
                    <label className="text-label">Your Name*</label>
                    <input
                      type="test"
                      className="form-control"
                      placeholder="Name"
                      onChange={(e) => setname(e.target.value)}
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-6 mb-2">
                  <div className="form-group mb-3">
                    <label className="text-label">Blog Title*</label>
                    <input
                      type="test"
                      className="form-control"
                      placeholder="title"
                      onChange={(e) => setTitle(e.target.value)}
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-6 mb-2">
                  <div className="form-group mb-3">
                    <label className="text-label">Blog Story*</label>
                    <textarea
                      className="form-control"
                      id="val-suggestions"
                      name="val-suggestions"
                      rows="5"
                      placeholder="Tell your story"
                      onChange={(e) => setDesc(e.target.value)}
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-4">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Blog Image</h4>
              </div>
              {/* <ImagePreview>
                {productImg ? (
                  <>
                    <img src={productImg} alt="error!" />
                  </>
                ) : (
                  <h4>Property image upload preview will appear here!</h4>
                )}
              </ImagePreview> */}
              <ImagePreview>
                {/* <p className="text-white">Property image upload preview will appear here!</p> */}
                {file && (
                  <img className="writeImg" src={URL.createObjectURL(file)} alt="" />
                )}

              </ImagePreview>
            </div>
          </div>
          <div className="form-group mb-3 row">
            <div className="col-lg-8 ms-auto">
              <button type="submit" className="btn btn-primary">
                {createStatus === "pending" ? "Submitting" : "Submit"}
              </button>
            </div>
          </div>
        </div>
      </form>
    </Fragment>
  );
};

export default BlogCreate;

const ImagePreview = styled.div`
  margin: 2rem 0 2rem 2rem;
  padding: 2rem;
  height:150px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.1rem;
  color: rgb(78, 78, 78);

  img {
    max-width: 100%;
    max-height: 100%;
  }
`;