import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import ThemeContext from "./context/ThemeContext";
import store from "./reducer/store";
import { productsFetch } from "./slices/productsSlice";
import { personalFetch } from "./slices/personalSlice";
import { propertyFetch } from "./slices/propertySlice";
import { blogFetch } from "./slices/blogSlice";
import { propertyLLCFetch } from "./slices/llcSlice"
import { config } from "./slices/config"
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { WagmiProvider } from 'wagmi'
import { expenseFetch } from './slices/expenseSlice';
import { rentFetch } from './slices/rentSlice';

store.dispatch(rentFetch());
store.dispatch(expenseFetch());
store.dispatch(productsFetch());
store.dispatch(personalFetch());
store.dispatch(propertyFetch());
store.dispatch(blogFetch());
store.dispatch(propertyLLCFetch());

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <WagmiProvider config={config}>
        <QueryClientProvider client={queryClient}>
          {/* <BrowserRouter basename='/dashboard'> */}
          <BrowserRouter >
            <ThemeContext>
              <App />
            </ThemeContext>
          </BrowserRouter>
        </QueryClientProvider>
      </WagmiProvider>
    </Provider>
  </React.StrictMode>
);

reportWebVitals();
