import React, { useContext, useState } from "react";
/// React router dom
import { Link } from "react-router-dom";
import { ThemeContext } from "../../../context/ThemeContext";

//images
import logo1 from './../../../images/logo/logoo.png';
import logotext1 from './../../../images/logo/logoss.png';
import logoColor from './../../../images/logo/logoo.png';
import logoColorText from './../../../images/logo/logos.png';

export function  NavMenuToggle(){
	setTimeout(()=>{	
		let mainwrapper = document.querySelector("#main-wrapper");
		if(mainwrapper.classList.contains('menu-toggle')){
			mainwrapper.classList.remove("menu-toggle");
		}else{
			mainwrapper.classList.add("menu-toggle");
		}
	},200);
}


const NavHader = () => {
  const [toggle, setToggle] = useState(false);
  const { navigationHader, openMenuToggle, background } = useContext( ThemeContext);
  return (
    <div className="nav-head">
      <Link to="/" className="brand-logo"> 
		{/* <img src={logoColorText}  className="logo-abbr" alt=""/> */}
		<img src={logoColorText} className="brand-title" alt=""/>
		{/* <img src={logo1} className="logo-color" alt="" /> */}
		{/* <img src={logoColorText} className="brand-title color-title" alt="" /> */}
      </Link>
    </div>
  );
};

export default NavHader;

