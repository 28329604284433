import React from 'react';
import { useDispatch } from 'react-redux';
import {useNavigate } from 'react-router-dom';
import logout from "../../../images/logout.png";
import { logoutUser } from "../../../slices/authSlice";



function LogoutPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();


  function onLogout() {
    dispatch(logoutUser(null))
    navigate("/login")
  }
  return (
    <>
      <button onClick={onLogout} as="button"
        type="button"
        style={{
          backgroundColor: "#9568FF",
          color: "white",
          borderRadius: "8px",
          borderColor: "#9568FF",
          marginLeft: "10px",
          marginTop: "10px",
          width: "150px",
        }}
      >
        LOGOUT
        <img
          src={logout}
          alt="wallets"
          height="5px"
          width="5px"
          style={{ marginLeft: "10px" }}
        />
      </button>
    </>
  )
}
export default LogoutPage;