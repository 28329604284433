import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { AnimatePresence, motion } from "framer-motion";
import { useAppSelector, useAppDispatch } from '../../reducer/store';
// import { useNavigate } from "react-router-dom";
// import { CONTRACT_ABIS } from '../contract/property';
// import { PropertyToken_ADDRESS } from '../contract/PropertyToken';
import { updatAccount } from '../../slices/web3ContractSlice';
import { productDelete } from '../../slices/productsSlice';
import { RiDeleteBinLine } from "react-icons/ri";
import { IoCheckmarkDone } from "react-icons/io5";
import { RiFileCopyLine } from 'react-icons/ri';
import { Dropdown, Modal } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';

const PropertyList = () => {
    const [contactModal, setContactModal] = useState(false);
    const [textToCopy, setTextToCopy] = useState();
    const [isCopying, setIsCopying] = useState(false);
    const [tokenaddress, settokenaddress] = useState();
    // const [loading, setloading] = useState();
    const [popular, setPopular] = useState([]);
    const [filtered, setFiltered] = useState([]);
    const [activeGenre, setActiveGenre] = useState(0);
    const [prop, setprop] = useState(0);
    const dispatch = useAppDispatch()
    const { items: data, status } = useAppSelector((state) => state.products);
    const { web3, contract, accounts } = useAppSelector((state) => state.web3Connect);

    const copyToClipboard = () => {
        // setIsCopying(true);
        navigator.clipboard.writeText(textToCopy)
            .then(() => {
                setIsCopying(true);
                // alert('Text copied to clipboard');
            })
            .catch((err) => {
                setIsCopying(false);
                // console.error('Failed to copy: ', err);
            });
    };



    const handleDelete = (id) => {
        dispatch(productDelete(id));
    };


    useEffect(() => {
        fetchPopular();
    }, [])

    // useEffect(() => {
    //     dispatch(filtered);
    // }, [dispatch])

    function fetchPopular() {
        setPopular(data);
        setFiltered(data);
    }
    useEffect(() => {
        if (activeGenre === 0) {
            setFiltered(data);
            return;
        }
        const filtered = popular.filter((card) =>
            card.propertytype.includes(activeGenre)
        );
        setFiltered(filtered);
    }, [activeGenre]);


    return (
        <>
            <div className="row">
                <div className="col-xl-12">
                    <div className="card Infra">
                        <div className="card-header border-0">
                            <div className="site-filters clearfix center m-b40">
                                <ul className="filters" data-bs-toggle="buttons">
                                    <li data-filter="" className={`btn ${activeGenre === 0 ? "active" : ""}`}>
                                        <Link to={"#"} className="site-button" onClick={() => setActiveGenre(0)}>Show All</Link>
                                    </li>
                                    <li data-filter=".infra" className={`btn ${activeGenre === "Single Family" ? "active" : ""}`}>
                                        <Link to={"#"} className="site-button" onClick={() => setActiveGenre("Single Family")}>Single Family</Link> </li>
                                    <li data-filter=".gaming" className={`btn ${activeGenre === "Multiple Family" ? "active" : ""}`}>
                                        <Link to={"#"} className="site-button" onClick={() => setActiveGenre("Multiple Family")}>Multiple Family</Link>
                                    </li>
                                    <li data-filter=".trade" className={`btn ${activeGenre === "Mised Use" ? "active" : ""}`}>
                                        <Link to={"#"} className="site-button" onClick={() => setActiveGenre("Mised Use")}>Mixed Use</Link>
                                    </li>
                                </ul>
                            </div>
                            {/* <li style={{ textAlign: "right" }} className={`btn`}>
                                <button className="btn btn-primary" onClick={() => setContactModal(true)}>Create Property
                                </button>
                            </li> */}
                        </div>
                    </div>
                </div>
                <div className="col-xl-12">
                    <div className="row">
                        <div className="col-xl-2 col-lg-4">
                            <div className="card check-filter">
                                <div className="card-body site-filters clearfix">
                                    <ul className="filters d-grid" data-bs-toggle="radio">
                                        <li data-filter="" className="form-check">
                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" defaultChecked
                                                onClick={() => setActiveGenre(0)}
                                            />
                                            <label className="form-check-label" htmlFor="flexRadioDefault1">All</label>
                                        </li>
                                        <li data-filter=".rated" className="form-check" >
                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2"
                                                onClick={() => setActiveGenre(1)}
                                            />
                                            <label className="form-check-label" htmlFor="flexRadioDefault2">Primary Market</label>
                                        </li>
                                        <li data-filter=".notrated" className="form-check">
                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault3"
                                                onClick={() => setActiveGenre(2)}
                                            />
                                            <label className="form-check-label" htmlFor="flexRadioDefault3">2ndary Market</label>
                                        </li>
                                        <li data-filter=".spo" className="form-check">
                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault4"
                                                onClick={() => setActiveGenre(4)}
                                            />
                                            <label className="form-check-label" htmlFor="flexRadioDefault4">Closed</label>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-10">
                            <ul
                                //layout  
                                className="row"
                            //transition={{ duration: 0.3 }}
                            >
                                <AnimatePresence>
                                    {/* {filtered.map((item, index) => {
                                        return (
                                            <motion.li
                                                layout
                                                initial={{ scale: 0, opacity: 0 }}
                                                animate={{ scale: 1, opacity: 1 }}
                                                exit={{ scale: 0, opacity: 0 }}
                                                transition={{ duration: 0.2 }}
                                                className="col-xl-3 col-md-6 infra rated px-3" key={index}
                                            >
                                                <div className="card pull-up">
                                                    <div className="card-body align-items-center flex-wrap">
                                                        <div className="d-flex align-items-center mb-4">
                                                            <Link to={"#"} className="ico-icon">
                                                                <img width="24" height="24" src={item.image?.url} alt="" />
                                                            </Link>
                                                            <div className="ms-0">
                                                                <h4 className="heading mb-0 fs-8">{item.name}</h4>
                                                                <span className='fs-8'>{item.propertytype}</span>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex align-items-center justify-content-between">
                                                            <div>
                                                                <p className="mb-0 fs-14 text-black">Created</p>
                                                                <span className="fs-12">{new Date(item.createdAt).toDateString()}</span>
                                                            </div>
                                                            <div className='ms-4'>
                                                                <p className="mb-0 fs-14 text-success">{item.location}</p>
                                                                <span className="fs-12">{item.propaddress}</span>
                                                            </div>
                                                        </div>
                                                        <div className=" d-flex align-items-center justify-content-between">
                                                            <div>
                                                                <button className="btn btn-secondary fs-8"
                                                                    onClick={() => handleDelete(item._id)}
                                                                >Delete
                                                                </button>
                                                            </div>
                                                            <div className='ms-4'>
                                                                <button className="btn btn-secondary fs-8"
                                                                    onClick={() => StartSell(item.uid)}
                                                                >
                                                                    Start
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </motion.li>
                                        )
                                    })} */}
                                    <div className="col-xl-12">
                                        <div className="row">
                                            {filtered.map((data, index) => (
                                                <motion.li
                                                    layout
                                                    initial={{ scale: 0, opacity: 0 }}
                                                    animate={{ scale: 1, opacity: 1 }}
                                                    exit={{ scale: 0, opacity: 0 }}
                                                    transition={{ duration: 0.2 }}
                                                    className="col-xl-3 col-md-6 infra rated px-3" key={index}>
                                                    <div className="card contact_list ">
                                                        <div className="card-body text-center">
                                                            <div className="user-content">
                                                                <div className="user-info">
                                                                    <div className="user-img">
                                                                        <Link to={"/propertydetails/" + data.uid} className="ico-icon">
                                                                            <img src={data.image?.url} alt="" />
                                                                        </Link>
                                                                    </div>
                                                                    <div className="user-details">
                                                                        <div className="ms-0">
                                                                            <span className='fs-8'>{data.propertytype}</span>
                                                                        </div>
                                                                        <h4 className="user-name">{data.uid?.substring(0, 7) + "...."}</h4>
                                                                        <p className="mb-0 fs-14 text-success">{data.location}</p>
                                                                        <div className='col'>
                                                                            <p className="mb-0 fs-14 text-black">Created</p>
                                                                            <span className="fs-8">{new Date(data.createdAt).toDateString()}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <Dropdown style={{ width: "24px", height: "24px" }}>
                                                                    <Dropdown.Toggle as="div" className="i-false btn-link btn sharp tp-btn btn-primary pill"
                                                                    onClick={() => handleDelete(data._id)}
                                                                    >
                                                                        {/* <a style={{fontSize:"8px"}}>Delete</a> */}
                                                                        <RiDeleteBinLine style={{fontSize:"18px"}}/>
                                                                    </Dropdown.Toggle>
                                                                </Dropdown>

                                                            </div>
                                                            {/* <div className=" align-items-center justify-content-between">
                                                                <Link to={"/propertydetails/" + data.id} className="btn btn-outline-primary  ">
                                                                    More Details
                                                                </Link>
                                                            </div> */}
                                                            <div className=" align-items-center mt-2  justify-content-between">
                                                                <Link to={"/propertydetails/" + data.uid} className="btn btn-secondary ">
                                                                    More Details
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </motion.li>
                                            ))}


                                        </div>
                                    </div>
                                </AnimatePresence>
                            </ul>

                        </div>

                    </div>
                </div>

            </div>
            {/* <Modal className="modal fade" id="exampleModal" centered show={contactModal} onHide={setContactModal}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Create Property</h5>
                        <button type="button" className="btn-close" onClick={() => setContactModal(false)}></button>
                    </div>
                    <div className="modal-body">
                        <h5 className="form-label d-block">SEDT Address</h5>
                        <div className=" text-center mt-2 fw-bold">
                            <div className="flex flex-row cursor-pointer flex-wrap sm:mt-10 ">
                                <div className='row'>
                                    <div className='col'>
                                        <label className="form-label d-block">{textToCopy}</label>
                                    </div>
                                    <div className='col'>
                                        <a onClick={copyToClipboard}>
                                            {isCopying ? (
                                                <>
                                                    <RiFileCopyLine /> <IoCheckmarkDone />
                                                </>
                                            ) : (
                                                <RiFileCopyLine />
                                            )}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br />
                        <label className="form-label d-block">Copy and Past the Property Token Address</label>
                        <input type="text" className="form-control w-100" placeholder="Enter SEDT Address" />
                    </div>
                    <div className="modal-body">
                        <a>Note: Only Property Admin can create the Property </a>
                        <Spinner animation="border" size="sm" />
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={() => setContactModal(false)}>Close</button>
                        <button type="button" className="btn btn-primary" onClick={() => CreateProperties()}>
                            {loading ? (
                                <> <Spinner animation="border" size="sm" /></>
                            ) : (
                                <>
                                    Create
                                </>)}
                        </button>
                    </div>
                </div>
            </Modal> */}
        </>
    )
}
export default PropertyList;