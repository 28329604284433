import React, { useState, useEffect } from "react";
import { useAppDispatch } from "../../../reducer/store";
import { FILTER } from "../../../slices/propFilter";
import {Dropdown} from 'react-bootstrap';


const LocationDropdown = () => {
  const [types, settype] = useState("");
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      FILTER(types),
    );
  },)

  const handleSelect = (eventKey) => {
    settype(eventKey);
  };

  return (

    <div className="input-group">
      <button className="btn btn-outline-primary btn-outline-primary form-control" type="button">Property Type</button>
      <Dropdown className="d-flex" onSelect={handleSelect}>
        <Dropdown.Toggle as="button" className="btn btn-primary btn-outline-primary left-radius" title="All Properties">{types}</Dropdown.Toggle>
        <Dropdown.Menu align="end" style={{overflowY:"scroll",  maxHeight: "calc(65vh - 150px)", backgroundColor:"#ebebeb"}}>
        <Dropdown.Item eventKey="" title="All Properties">All Properties</Dropdown.Item>
          <Dropdown.Item eventKey="Single Family" >Single Family</Dropdown.Item>
          <Dropdown.Item eventKey="Multifamily">Multifamily</Dropdown.Item>
          <Dropdown.Item eventKey="Mixed-Use">Mixed-Use</Dropdown.Item>
          <Dropdown.Item eventKey="Commercial">Commercial</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default LocationDropdown;

